import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

// Views
import KioskInventoryView from './kioskInventoryView';

// Types
import { KioskInventoryContainerProps } from './types';

// Route store
import getDomain from './domain';

// Global Stores
import stores from '@clientCommon/app/bootstrap/store';

const KioskInventoryContainer = ({ kioskId }: KioskInventoryContainerProps) => {
  const domain = useMemo(() => getDomain().create(), []);

  const fetchInventory = useCallback(async () => {
    if (kioskId) {
      return stores.models.kiosks.async.getComputedInventory(kioskId);
    }
  }, [kioskId]);

  useEffect(() => {
    (async () => {
      if (kioskId) {
        const inventory = await fetchInventory();
        if (inventory) {
          domain.setValue('inventory', inventory);
        }
      }
    })();
  }, [kioskId]);

  useEffect(() => {
    return () => {
      domain.reset();
    };
  }, []);

  return <KioskInventoryView
    domain={domain}
    fetchInventory={fetchInventory}
  />;
};

export default observer(KioskInventoryContainer);
