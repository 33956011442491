import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

// Types
import { ListItemViewProps } from './types';

// Components
import H4 from '@clientCommon/library/components/composition/h4';

// Styles
import './drillDownLayoutListItemStyles.scss';

function ListItemView({ data, label, render, isActive, url, title, icons }: ListItemViewProps) {
  const labelText = useMemo(() => {
    if (!label) return;
    if (label.fromData) return label.fromData(data);
  }, [label]);

  return (
    <Link className={`c-ListItemView ${isActive && 'active'}`} to={url}>
      {labelText && (
        <div className="list-item-label-container">
          <div className="list-item-label">{labelText}</div>
        </div>
      )}
      <div className="list-item-container">
        {icons && icons.length && (
          <div className="icons-container">
            {icons.map((icon) => (
              <div key={icon} className="icon">
                <img src={icon} />
              </div>
            ))}
          </div>
        )}
        <div className="content-container">
          {title && (
            <div className="title-container">
              {title.render
                ? title.render(data)
                : title.fromData && <H4 className={`title ${!isActive && 'neutral'}`}>{title.fromData(data)}</H4>}
              {title.right && title.right.render && <div className="title-right">{title.right.render(data)}</div>}
            </div>
          )}
          {render && <div className="body-container">{render({ data })}</div>}
        </div>
      </div>
    </Link>
  );
}

export default observer(ListItemView);
