import DrugSuggestion from './components/drugSuggestion';
import PatientSuggestion from './components/patientSuggestion';
import PhysicianSuggestion from './components/physicianSuggestion';

export enum Entity {
  Drug = 'drug',
  Patient = 'patient',
  Physician = 'physician',
}

export const SUGGESTION_COMPONENT = {
  [Entity.Drug]: DrugSuggestion,
  [Entity.Patient]: PatientSuggestion,
  [Entity.Physician]: PhysicianSuggestion,
};
