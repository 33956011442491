import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

type Props = {
  children: React.ReactNode;
};

const TableHeader = ({ children }: Props) => <div className="c-TableLayoutTableHeader">{children}</div>;

export default observer(TableHeader);
