import { observer } from 'mobx-react';
import React from 'react';

// Types
import { HeaderViewProps } from './types';

// Components
import H3 from '@clientCommon/library/components/composition/h3';
import SearchInput from '@clientCommon/library/components/searchInput';

// Styles
import './drillDownLayoutListHeaderStyles.scss';

function HeaderView({ title, filter }: HeaderViewProps) {
  return (
    <div className="c-HeaderView">
      <div className="title">
        <H3>{title}</H3>
      </div>
      <div className="filter-container">
        <SearchInput
          value={filter?.term}
          onChange={filter?.onChange}
          onSearch={filter?.onSearch || (() => {})}
          debounce={{
            wait: 250,
          }}
        />
      </div>
    </div>
  );
}

export default observer(HeaderView);
