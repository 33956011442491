import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Types
import { KiosksContainerProps } from './types';

// Store
import store from '@clientCommon/app/bootstrap/store';
import getDomain from './domain';

// Helpers
import listKiosks from './helpers/listKiosks';

// Views
import KioskView from './kiosksView';


function KioskContainer (props: KiosksContainerProps) {
  const domain = useMemo(() => getDomain({}).create(), []);

  const navigate = useNavigate();
  const { kioskId } = useParams();

  const [filter, setFilter] = useState('');

  const navigateToKiosk = useCallback((kioskId: string | null) => {
    if (!domain.kiosks.size) {
      return;
    }

    if (kioskId && domain.kiosks.get(kioskId)) {
      navigate(`/kiosks/${kioskId}`);
    } else {
      navigate(`/kiosks/${domain.kioskList[0].id}`);
    }
  }, [domain.kiosks, domain.kioskList, navigate]);

  const onFilterSearch = useCallback(async (filterValue: string) => {
    const kiosks = await listKiosks(filterValue);
    domain.setKiosks(kiosks);
  }, []);

  useEffect(() => {
    (async () => {
      const kiosks = await listKiosks();
      domain.addKiosks(kiosks);
    })();
  }, []);

  useEffect(() => {
    const activeKioskId = kioskId || null;
    domain.setValue('activeKioskId', activeKioskId);
    navigateToKiosk(activeKioskId);
  }, [kioskId, navigateToKiosk]);

  const formatTemperature = useCallback((temperature: number) => store.models.kiosks.formatTemperature(temperature), []);

  return <KioskView
    domain={domain}
    filter={filter}
    formatTemperature={ formatTemperature }
    onFilterChange={setFilter}
    onFilterSearch={onFilterSearch}
  />;
}

export default observer(KioskContainer);
