import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import PrescriptionFillsView from './lotPrescriptionFillsView';

import store from '@clientCommon/app/bootstrap/store';

import { PrescriptionFillDecorated } from '@common/models/prescriptionFills/types';
import { LotPrescriptionFillsContainerProps } from './types';

const PrescriptionFillsContainer = ({ activeLotId }: LotPrescriptionFillsContainerProps) => {
  const [prescriptionFillsList, setPrescriptionFillsList] = useState([] as PrescriptionFillDecorated[]);

  const formattedPrescriptionFillsList = useMemo(() => {
    return prescriptionFillsList.map((prescriptionFill) => {
      const { drug, patient } = prescriptionFill.prescription;
      return {
        name: `${patient.firstName} ${patient.lastName}`,
        externalPharmacyId: patient.externalPharmacyId || 'N/A',
        phoneNumber: patient.phoneNumber || 'N/A',
        drug: {
          name: drug.name,
          NDC: drug.NDC,
        },
        creationDate: prescriptionFill.createdAt ? new Date(prescriptionFill.createdAt).toLocaleDateString() : 'N/A',
      };
    });
  }, [prescriptionFillsList]);

  const listPrescriptionFills = useCallback(
    async (filterValue?: string) => {
      const prescriptionFills = await store.models.prescriptionFills.async.listByLotId(activeLotId, filterValue);
      setPrescriptionFillsList(prescriptionFills);
    },
    [activeLotId]
  );

  const onPrescriptionFillSearch = useCallback(
    async (value: string) => {
      return listPrescriptionFills(value);
    },
    [listPrescriptionFills]
  );

  useEffect(() => {
    listPrescriptionFills();
  }, []);

  return (
    <PrescriptionFillsView
      isBusy={Boolean(store.models.prescriptionFills.async.busy.listByLotId)}
      prescriptionFillsList={formattedPrescriptionFillsList}
      onPrescriptionFillSearch={onPrescriptionFillSearch}
    />
  );
};

export default observer(PrescriptionFillsContainer);
