import { keyBy as _keyBy } from 'lodash';

import store from '@clientCommon/app/bootstrap/store';

import { DrugsDomain, DrugsQuery } from '../types';

const listDrugs = async (domain: DrugsDomain, query: DrugsQuery) => {
  const drugs = await store.models.drugs.async.list(query.filter, query);
  domain.setValue('drugsById', _keyBy(drugs, 'id') as any);

  return drugs;
};

export default listDrugs;
