import React from 'react';
import { observer } from 'mobx-react';

// Types
import { LoginViewProps } from './types';

// Images
import kioskHero from '@clientCommon/library/assets/icons/kioskHero.svg';
import medifriendRxLogo from '@clientCommon/library/assets/logos/medifriendText.svg';

import './loginStyles.scss';

function LoginView({ domain }: LoginViewProps) {
  return (
    <div className="c-LoginView">
      <div className="primary-panel">
        <div className="sub-panel left-panel">
          <img className="logo-icon" src={medifriendRxLogo} />
          <img className="kiosk-image" src={kioskHero} />
        </div>
        <form
          className="sub-panel right-panel"
          onSubmit={(e) => {
            e.preventDefault();
            domain.login();
          }}
        >
          <div className="greeting-text">
            <h3>Sign In</h3>
          </div>
          <div className="input-fields">
            <div className="input-field">
              <div className="input-label">Email</div>
              <div className="input-email">
                <input
                  autoComplete="off"
                  value={domain.email || ''}
                  onChange={(e) => {
                    domain.setValue('email', e.target.value);
                    domain.setValue('password', null);
                  }}
                />
              </div>
            </div>
            <div className="input-field">
              <div className="input-label">Password</div>
              <div className="input-email">
                <input
                  type="password"
                  value={domain.password || ''}
                  onChange={(e) => domain.setValue('password', e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <input className="login-button" type="submit" value="Log In" />
            {
              // !!loginRouteStore.errorMessage &&
              // <div className='error-message'>
              //   {
              //     loginRouteStore.errorMessage
              //   }
              // </div>
            }
            <div className="sign-up-button">
              {/* <Link to='/signup'>
              Sign Up
            </Link> */}
            </div>
            <div className="forgot-password-button">
              {/* <Link to='/reset-password'>
              Forgot email or password?
            </Link> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default observer(LoginView);
