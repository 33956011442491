import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

// Views
import KioskContainersView from './kioskContainersView';

// Types
import { KioskContainersContainerProps } from './types';

// Route store
import getDomain from './domain';

const KioskContainersContainer = ({ kioskId }: KioskContainersContainerProps) => {
  const domain = useMemo(() => getDomain({ kioskId }).create(), [kioskId]);

  useEffect(() => {
    if (kioskId) {
      domain.fetchContainers();
      domain.fetchKioskInventory();
    }
  }, [kioskId]);

  // Deselect containers, that are no longer being displayed on the screen (because of searching).
  useEffect(() => {
    const { activeContainers, selectedContainerIds } = domain;
    selectedContainerIds.forEach((selectedContainerId) => {
      if (!activeContainers.get(selectedContainerId)) {
        domain.deleteSelectedContainer(selectedContainerId);
      }
    });
  }, [domain.activeContainers.size]);

  useEffect(() => {
    return () => {
      domain.reset();
    };
  }, []);

  return <KioskContainersView domain={domain} />;
};

export default observer(KioskContainersContainer);
