import React from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react';

import CrossIcon from '@clientCommon/library/assets/icons/cross.svg';

import './modalStyles.scss';

Modal.setAppElement('#root');

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  close: () => void;
  title: string;
  shouldCloseOnOverlayClick?: boolean;
  modalClassName?: string;
  overlayClassName?: string;
  modalStyles?: React.CSSProperties;
  overlayStyles?: React.CSSProperties;
};

const ModalWrapper = ({
  children,
  isOpen = false,
  close,
  shouldCloseOnOverlayClick = true,
  modalClassName = '',
  overlayClassName = '',
  modalStyles = {},
  overlayStyles = {},
  title,
}: Props) => (
  <Modal
    overlayClassName={`ModalOverlay ${overlayClassName}`}
    className={`c-Modal ${modalClassName}`}
    isOpen={isOpen}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    onRequestClose={close}
    style={{
      content: modalStyles,
      overlay: { zIndex: 1101, ...overlayStyles },
    }}
  >
    {!!title && (
      <div className="header">
        <div className="title-container">
          <div className="title">{title}</div>
        </div>
        <div className="close-modal-icon-container">
          <img alt="Close Modal" className="close-modal-icon" src={CrossIcon} onClick={close} />
        </div>
      </div>
    )}
    {children}
  </Modal>
);

export default observer(ModalWrapper);
