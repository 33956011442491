import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

// Types
import { Grouping, ListContainerProps } from './types';

// Views
import ListView from './drillDownLayoutListView';

function ListContainer({ title, dataList, filter, item, group, isBusy = false }: ListContainerProps) {
  const grouped = useMemo(() => {
    if (!group?.key) return null;
    const key = group.key;
    const sortKey = group?.sort?.key || key;
    const labelKey = group?.label?.key || key;

    const orphans: any[] = [];

    const grouped: Record<string, Grouping> = {};
    dataList.forEach((item) => {
      const groupKeyValue = get(item, key);
      if (groupKeyValue === 'N/A') throw Error(`Group Key Value cannot be "N/A," key is reserved by component.`);
      if (groupKeyValue) {
        const grouping = (grouped[groupKeyValue] = grouped[groupKeyValue] || {
          keyValue: groupKeyValue,
          sortKeyValue: get(item, sortKey),
          labelKeyValue: get(item, labelKey),
          list: [],
        });
        grouping.list.push(item);
      } else {
        orphans.push(item);
      }
    });

    const formatted = orderBy(Object.values(grouped), ['sortKey']);
    if (orphans.length)
      formatted.push({
        keyValue: 'N/A',
        sortKeyValue: 'N/A',
        labelKeyValue: 'N/A',
        list: orphans,
      });

    return formatted;
  }, [dataList, group]);

  return <ListView title={title} dataList={dataList} filter={filter} item={item} grouped={grouped} isBusy={isBusy} />;
}

export default observer(ListContainer);
