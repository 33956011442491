import React from 'react';
import { observer } from 'mobx-react';
import { isBoolean as _isBoolean } from 'lodash';

// Components
import AutocompleteTitledInput from './components/autocompleteTitledInput';
import ClearSectionButton from './components/clearSectionButton';
import ContentScreen from '@clientCommon/library/components/contentScreen';
import TitledInput from '@clientCommon/library/components/titledInput';
import TitledSelect from '@clientCommon/library/components/titledSelect';

// Constants
import { PRESCRIPTION_FILL_PARAMS } from './constants';
import { Entity } from './components/autocompleteTitledInput/constants';

// Icons
import DrugIcon from '@clientCommon/library/assets/icons/drug-active.svg';
import PatientIcon from '@clientCommon/library/assets/icons/patient-active.svg';
import PhysicianIcon from '@clientCommon/library/assets/icons/physician-active.svg';
import PrescriptionIcon from '@clientCommon/library/assets/icons/prescriptions-active.svg';

import { PrescriptionFillManualEntryViewProps } from './types';

// Styles
import './prescriptionFillManualEntryStyles.scss';

const PrescriptionFillsManualOrderView = ({
  domain,
  onDrugInputChange,
  onDrugSuggestionClick,
  onPatientInputChange,
  onPatientSuggestionClick,
  onPhysicianInputChange,
  onPhysicianSuggestionClick,
  upsertPrescriptionFillWithReferences,
}: PrescriptionFillManualEntryViewProps) => {
  return (
    <div className="r-PrescriptionFillsManualEntryView">
      <ContentScreen active={domain.isSubmitting} />
      <div className="header-container">
        <div className="title">Prescription Manual Entry</div>
        <div className={`kiosk-select ${domain.kioskOption ? '' : 'warning'}`}>
          <TitledSelect
            caption="Kiosk"
            placeholder="Select Kiosk to Route To"
            options={domain.kioskOptions}
            value={domain.kioskOption}
            onChange={(option: any) => domain.setValue('kioskOption', option)}
            isLoading={domain.isLoadingKioskOptions}
          />
        </div>
      </div>
      <div className="data-input-container">
        <div className="entity-container">
          <div className="header">
            <div className="title-container">
              <img className="icon" alt="Patient" src={PatientIcon} />
              <div className="title">Patient</div>
            </div>
            <ClearSectionButton onClick={() => domain.resetEntity('patient')} />
          </div>
          <div className="inputs">
            <div className="row">
              <AutocompleteTitledInput
                type={Entity.Patient}
                caption="First Name"
                value={domain.patient.firstName}
                onInputChange={(value) => onPatientInputChange('firstName', value)}
                required={PRESCRIPTION_FILL_PARAMS['patient.firstName'].required}
                suggestions={domain.suggestions.patient.firstName}
                onSuggestionClick={(patient) => onPatientSuggestionClick(patient, 'firstName')}
                clearSuggestions={() => domain.setSuggestionFieldValue('patient', 'firstName', [])}
              />
              <AutocompleteTitledInput
                type={Entity.Patient}
                caption="Last Name"
                value={domain.patient.lastName}
                onInputChange={(value) => onPatientInputChange('lastName', value)}
                required={PRESCRIPTION_FILL_PARAMS['patient.lastName'].required}
                suggestions={domain.suggestions.patient.lastName}
                onSuggestionClick={(patient) => onPatientSuggestionClick(patient, 'lastName')}
                clearSuggestions={() => domain.setSuggestionFieldValue('patient', 'lastName', [])}
              />
            </div>
            <div className="row">
              <TitledInput
                className="input-with-margin"
                inputFieldClassNames={['max-width']}
                caption="Date Of Birth"
                // placeholder='YYYY-MM-DD'
                placeholder="MM/DD/YYYY"
                value={domain.patient.DOB}
                onChange={(value: string) => domain.setPatientFieldValue('DOB', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['patient.DOB'].regex}
                required={PRESCRIPTION_FILL_PARAMS['patient.DOB'].required}
              />
            </div>
            <div className={`identifiers-grouping ${domain.doesPatientHaveIdentifier ? '' : 'warning'}`}>
              <div className="row">
                <AutocompleteTitledInput
                  type={Entity.Patient}
                  caption="Unique Identifier"
                  value={domain.patient.externalPharmacyId}
                  validationRegex={PRESCRIPTION_FILL_PARAMS['patient.externalPharmacyId'].regex}
                  onInputChange={(value) => onPatientInputChange('externalPharmacyId', value)}
                  required={PRESCRIPTION_FILL_PARAMS['patient.externalPharmacyId'].required}
                  suggestions={domain.suggestions.patient.externalPharmacyId}
                  onSuggestionClick={(patient) => onPatientSuggestionClick(patient, 'externalPharmacyId')}
                  clearSuggestions={() => domain.setSuggestionFieldValue('patient', 'externalPharmacyId', [])}
                />
              </div>
              <div className="row">
                <TitledInput
                  className="input-with-margin"
                  inputFieldClassNames={['max-width']}
                  caption="Email Address"
                  value={domain.patient.email}
                  onChange={(value: string) => domain.setPatientFieldValue('email', value || null)}
                  validationRegex={PRESCRIPTION_FILL_PARAMS['patient.email'].regex}
                  required={PRESCRIPTION_FILL_PARAMS['patient.email'].required}
                />
                <TitledInput
                  className="input-with-margin"
                  inputFieldClassNames={['max-width']}
                  caption="Phone Number"
                  placeholder="9876543210"
                  value={domain.patient.phoneNumber}
                  onChange={(value: string) => domain.setPatientFieldValue('phoneNumber', value || null)}
                  validationRegex={PRESCRIPTION_FILL_PARAMS['patient.phoneNumber'].regex}
                  required={PRESCRIPTION_FILL_PARAMS['patient.phoneNumber'].required}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="entity-container">
          <div className="header">
            <div className="title-container">
              <img className="icon" alt="Drug" src={DrugIcon} />
              <div className="title">Drug</div>
            </div>
            <ClearSectionButton onClick={() => domain.resetEntity('drug')} />
          </div>
          <div className="inputs">
            <div className="row">
              <AutocompleteTitledInput
                type={Entity.Drug}
                caption="Name"
                value={domain.drug.name}
                onInputChange={(value) => onDrugInputChange('name', value)}
                required={PRESCRIPTION_FILL_PARAMS['drug.name'].required}
                suggestions={domain.suggestions.drug.name}
                onSuggestionClick={(drug) => onDrugSuggestionClick(drug, 'name')}
                clearSuggestions={() => domain.setSuggestionFieldValue('drug', 'name', [])}
              />
              <AutocompleteTitledInput
                type={Entity.Drug}
                caption="NDC"
                value={domain.drug.NDC}
                onInputChange={(value) => onDrugInputChange('NDC', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['drug.NDC'].regex}
                required={PRESCRIPTION_FILL_PARAMS['drug.NDC'].required}
                suggestions={domain.suggestions.drug.NDC}
                onSuggestionClick={(drug) => onDrugSuggestionClick(drug, 'NDC')}
                clearSuggestions={() => domain.setSuggestionFieldValue('drug', 'NDC', [])}
              />
              <TitledInput
                className="input-with-margin"
                inputFieldClassNames={['max-width']}
                caption="Quantity"
                value={domain.drug.quantity}
                onChange={(value: string) => domain.setDrugFieldValue('quantity', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['drug.quantity'].regex}
                required={PRESCRIPTION_FILL_PARAMS['drug.quantity'].required}
              />
            </div>
          </div>
        </div>
        <div className="entity-container">
          <div className="header">
            <div className="title-container">
              <img className="icon" alt="Physician" src={PhysicianIcon} />
              <div className="title">Physician</div>
            </div>
            <ClearSectionButton onClick={() => domain.resetEntity('physician')} />
          </div>
          <div className="inputs">
            <div className="row">
              <AutocompleteTitledInput
                type={Entity.Physician}
                caption="First Name"
                value={domain.physician.firstName}
                onInputChange={(value) => onPhysicianInputChange('firstName', value)}
                required={PRESCRIPTION_FILL_PARAMS['physician.firstName'].required}
                suggestions={domain.suggestions.physician.firstName}
                onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'firstName')}
                clearSuggestions={() => domain.setSuggestionFieldValue('physician', 'firstName', [])}
              />
              <AutocompleteTitledInput
                type={Entity.Physician}
                caption="Last Name"
                value={domain.physician.lastName}
                onInputChange={(value) => onPhysicianInputChange('lastName', value)}
                required={PRESCRIPTION_FILL_PARAMS['physician.lastName'].required}
                suggestions={domain.suggestions.physician.lastName}
                onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'lastName')}
                clearSuggestions={() => domain.setSuggestionFieldValue('physician', 'lastName', [])}
              />
            </div>
            <div className={`identifiers-grouping ${domain.doesPhysicianHaveIdentifier ? '' : 'warning'}`}>
              <div className="row">
                <AutocompleteTitledInput
                  type={Entity.Physician}
                  caption="NPI"
                  value={domain.physician.NPI}
                  onInputChange={(value) => onPhysicianInputChange('NPI', value)}
                  validationRegex={PRESCRIPTION_FILL_PARAMS['physician.NPI'].regex}
                  required={PRESCRIPTION_FILL_PARAMS['physician.NPI'].required}
                  suggestions={domain.suggestions.physician.NPI}
                  onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'NPI')}
                  clearSuggestions={() => domain.setSuggestionFieldValue('physician', 'NPI', [])}
                />
                <AutocompleteTitledInput
                  type={Entity.Physician}
                  caption="SPI"
                  value={domain.physician.SPI}
                  onInputChange={(value) => onPhysicianInputChange('SPI', value)}
                  validationRegex={PRESCRIPTION_FILL_PARAMS['physician.SPI'].regex}
                  required={PRESCRIPTION_FILL_PARAMS['physician.SPI'].required}
                  suggestions={domain.suggestions.physician.SPI}
                  onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'SPI')}
                  clearSuggestions={() => domain.setSuggestionFieldValue('physician', 'SPI', [])}
                />
              </div>
              <div className="row">
                <AutocompleteTitledInput
                  type={Entity.Physician}
                  caption="State License #"
                  value={domain.physician.stateLicenseNumber}
                  onInputChange={(value) => onPhysicianInputChange('stateLicenseNumber', value)}
                  validationRegex={PRESCRIPTION_FILL_PARAMS['physician.stateLicenseNumber'].regex}
                  required={PRESCRIPTION_FILL_PARAMS['physician.stateLicenseNumber'].required}
                  suggestions={domain.suggestions.physician.stateLicenseNumber}
                  onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'stateLicenseNumber')}
                  clearSuggestions={() => domain.setSuggestionFieldValue('physician', 'stateLicenseNumber', [])}
                />
                <AutocompleteTitledInput
                  type={Entity.Physician}
                  caption="Unique Identifier"
                  value={domain.physician.externalPharmacyId}
                  onInputChange={(value) => onPhysicianInputChange('externalPharmacyId', value)}
                  validationRegex={PRESCRIPTION_FILL_PARAMS['physician.externalPharmacyId'].regex}
                  required={PRESCRIPTION_FILL_PARAMS['physician.externalPharmacyId'].required}
                  suggestions={domain.suggestions.physician.externalPharmacyId}
                  onSuggestionClick={(physician) => onPhysicianSuggestionClick(physician, 'externalPharmacyId')}
                  clearSuggestions={() => domain.setSuggestionFieldValue('physician', 'externalPharmacyId', [])}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="entity-container">
          <div className="header">
            <div className="title-container">
              <img className="icon" alt="Prescription" src={PrescriptionIcon} />
              <div className="title">Prescription</div>
            </div>
            <ClearSectionButton
              onClick={() => {
                domain.resetEntity('prescription');
                domain.resetEntity('prescriptionFill');
              }}
            />
          </div>
          <div className="inputs">
            <div className="row">
              <TitledInput
                className="input-with-margin"
                inputFieldClassNames={['max-width']}
                caption="Rx ID"
                value={domain.prescription.rxId}
                onChange={(value) => domain.setPrescriptionFieldValue('rxId', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['prescription.rxId'].regex}
                required={PRESCRIPTION_FILL_PARAMS['prescription.rxId'].required}
              />
              <TitledInput
                className="input-with-margin"
                inputFieldClassNames={['max-width']}
                caption="Copay $"
                value={domain.prescriptionFill.copay}
                onChange={(value: string) => domain.setPrescriptionFillFieldValue('copay', value)}
                validationRegex={PRESCRIPTION_FILL_PARAMS['prescriptionFill.copay'].regex}
                required={PRESCRIPTION_FILL_PARAMS['prescriptionFill.copay'].required}
              />
            </div>
            <div className="row">
              <TitledInput
                className="input-with-margin"
                inputFieldClassNames={['max-width']}
                caption="Fill #"
                value={domain.prescriptionFill.fillNumber}
                onChange={(value: string) => domain.setPrescriptionFillFieldValue('fillNumber', Number(value))}
                validationRegex={PRESCRIPTION_FILL_PARAMS['prescriptionFill.fillNumber'].regex}
                required={PRESCRIPTION_FILL_PARAMS['prescriptionFill.fillNumber'].required}
              />
              <TitledInput
                className="input-with-margin"
                inputFieldClassNames={['max-width']}
                caption="Refills #"
                value={domain.prescription.refills}
                onChange={(value: string) =>
                  domain.setPrescriptionFieldValue('refills', value !== '' ? Number(value) : null)
                }
                validationRegex={PRESCRIPTION_FILL_PARAMS['prescription.refills'].regex}
                required={PRESCRIPTION_FILL_PARAMS['prescription.refills'].required}
              />
            </div>
            <div className="row">
              <TitledInput
                className="input-with-margin"
                inputFieldClassNames={['max-width']}
                caption="Directions"
                value={domain.prescription.directions}
                onChange={(value: string) => domain.setPrescriptionFieldValue('directions', value || null)}
                required={PRESCRIPTION_FILL_PARAMS['prescription.directions'].required}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="buttons-container">
          {!domain.wasManualOrderFulfilled && (
            <button
              className="btn btn-blue"
              disabled={!domain.canBeSubmitted}
              onClick={() => upsertPrescriptionFillWithReferences()}
            >
              Submit
            </button>
          )}
          <button className="btn btn-red" onClick={() => domain.reset()}>
            Clear All
          </button>
        </div>
        {_isBoolean(domain.wasManualOrderFulfilled) && (
          <div className={`result-message ${domain.wasManualOrderFulfilled ? 'success' : 'error'}`}>
            {domain.wasManualOrderFulfilled
              ? 'Manual prescription entry has successfully been fulfilled'
              : 'Error occurred when fulfilling a manual prescription entry'}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(PrescriptionFillsManualOrderView);
