import { MODAL_ROW_CLASSNAME, CLASSNAME_BY_PROP } from '../constants';

type Props = {
  shouldBeCenteredHorizontally?: boolean;
  shouldBeSpaceBetween?: boolean;
  shouldBoundToBottom?: boolean;
};

const constructClassName = (props: Props) => {
  const propNames = Object.keys(props);
  const classNames = propNames.reduce(
    (classNames, propName) => {
      const className: string | undefined = (CLASSNAME_BY_PROP as any)[propName];
      if (!className || !(props as any)[propName]) {
        return classNames;
      }

      classNames.push(`${MODAL_ROW_CLASSNAME}--${className}`);
      return classNames;
    },
    [MODAL_ROW_CLASSNAME]
  );

  return classNames.join(' ');
};

export default constructClassName;
