import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

import { Drug } from '@common/models/drugs/types';

type Body = Pick<Drug, 'name' | 'NDC' | 'quantity' | 'strength'> & { id?: string | null };

export default flow(function* (body: Body) {
  const response: Drug = yield http.put({
    url: `${API_BASE}${MODEL_ROUTE}`,
    body,
  });

  return response;
});
