import React from 'react';
import { observer } from 'mobx-react';

import DrillDownLayout from '@clientCommon/library/components/drillDownLayout';

import Info from './children/info';
import PrescriptionFills from './children/prescriptionFills';

import LotIcon from '@clientCommon/library/assets/icons/ln.svg';

import { LotsViewProps } from './types';

import './lotsViewStyles.scss';

const LotsView = ({ domain, filter, onFilterChange }: LotsViewProps) => {
  return (
    <div className="c-LotsView">
      <DrillDownLayout
        title={'Lots'}
        list={{
          isBusy: domain.isLoadingLots,
          item: {
            key: { path: 'id' },
            title: {
              fromData: (data) => data.number,
            },
            active: { fromData: (data) => Boolean(domain.activeLot && data.id === domain.activeLot.id) },
            url: { fromData: (data) => `/lots/${data.id}` },
            icons: [LotIcon],
          },
        }}
        dataList={domain.lotsList}
        filter={{
          term: filter,
          onChange: (term) => onFilterChange(term),
        }}
        content={{
          isReady: Boolean(domain.activeLot),
          tabs: [
            {
              header: {
                title: domain.activeLot?.number || '',
              },
              content: {
                title: 'Info',
                component: domain.activeLot ? <Info activeLot={domain.activeLot} /> : null,
              },
            },
            {
              header: {
                title: domain.activeLot?.number || '',
              },
              content: {
                title: 'Prescriptions',
                component: domain.activeLot ? <PrescriptionFills activeLotId={domain.activeLot.id} /> : null,
              },
            },
          ],
        }}
      />
    </div>
  );
};

export default observer(LotsView);
