import React from 'react';
import { observer } from 'mobx-react';

// Views
import AppFrameView from './appFrameView';

function AppFrameContainer() {
  return <AppFrameView />;
}

export default observer(AppFrameContainer);
