import { types } from 'mobx-state-tree';
import { get as _get } from 'lodash';

import store from '@clientCommon/app/bootstrap/store';

import doesValueMatchRegex from '@clientCommon/library/utils/doesValueMatchRegex';

import { PRESCRIPTION_FILL_PARAMS } from '../constants';

import { Kiosk } from '@common/models/kiosks/types';
import { PrescriptionFillManualEntryDomainProps } from '../types';

const getPrescriptionFillManualEntryRouteStore = ({}: PrescriptionFillManualEntryDomainProps) =>
  types.optional(
    types
      .model({
        kiosks: types.optional(types.array(types.frozen<Kiosk>()), []),
        kioskOption: types.maybeNull(
          types.model({
            label: types.maybeNull(types.string),
            value: types.maybeNull(types.string),
          })
        ),
        prescriptionFill: types.optional(
          types.model({
            copay: types.optional(types.string, ''),
            fillNumber: types.maybeNull(types.number),
          }),
          {}
        ),
        prescription: types.optional(
          types.model({
            rxId: types.optional(types.string, ''),
            refills: types.maybeNull(types.number),
            directions: types.maybeNull(types.string),
          }),
          {}
        ),
        drug: types.optional(
          types.model({
            name: types.optional(types.string, ''),
            NDC: types.optional(types.string, ''),
            quantity: types.optional(types.string, ''),
          }),
          {}
        ),
        patient: types.optional(
          types.model({
            firstName: types.optional(types.string, ''),
            lastName: types.optional(types.string, ''),
            DOB: types.optional(types.string, ''),
            email: types.maybeNull(types.string),
            phoneNumber: types.maybeNull(types.string),
            externalPharmacyId: types.optional(types.string, ''),
          }),
          {}
        ),
        physician: types.optional(
          types.model({
            firstName: types.optional(types.string, ''),
            lastName: types.optional(types.string, ''),
            NPI: types.optional(types.string, ''),
            SPI: types.optional(types.string, ''),
            stateLicenseNumber: types.optional(types.string, ''),
            externalPharmacyId: types.optional(types.string, ''),
          }),
          {}
        ),
        wasManualOrderFulfilled: types.maybeNull(types.boolean),
        suggestions: types.optional(
          types.model({
            patient: types.optional(
              types.model({
                firstName: types.optional(types.array(types.frozen()), []),
                lastName: types.optional(types.array(types.frozen()), []),
                externalPharmacyId: types.optional(types.array(types.frozen()), []),
              }),
              {}
            ),
            drug: types.optional(
              types.model({
                name: types.optional(types.array(types.frozen()), []),
                NDC: types.optional(types.array(types.frozen()), []),
              }),
              {}
            ),
            physician: types.optional(
              types.model({
                firstName: types.optional(types.array(types.frozen()), []),
                lastName: types.optional(types.array(types.frozen()), []),
                NPI: types.optional(types.array(types.frozen()), []),
                SPI: types.optional(types.array(types.frozen()), []),
                stateLicenseNumber: types.optional(types.array(types.frozen()), []),
                externalPharmacyId: types.optional(types.array(types.frozen()), []),
              }),
              {}
            ),
          }),
          {}
        ),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends typeof self[K]>(key: K, value: V) => (self[key] = value),
        setPrescriptionFillFieldValue: <
          K extends keyof typeof self.prescriptionFill,
          V extends typeof self.prescriptionFill[K]
        >(
          key: K,
          value: V
        ) => {
          self.prescriptionFill[key] = value;
        },
        setPrescriptionFieldValue: <K extends keyof typeof self.prescription, V extends typeof self.prescription[K]>(
          key: K,
          value: V
        ) => {
          self.prescription[key] = value;
        },
        setDrugFieldValue: <K extends keyof typeof self.drug, V extends typeof self.drug[K]>(key: K, value: V) => {
          self.drug[key] = value;
        },
        setPatientFieldValue: <K extends keyof typeof self.patient, V extends typeof self.patient[K]>(
          key: K,
          value: V
        ) => {
          self.patient[key] = value;
        },
        setPhysicianFieldValue: <K extends keyof typeof self.physician, V extends typeof self.physician[K]>(
          key: K,
          value: V
        ) => {
          self.physician[key] = value;
        },
        setSuggestionFieldValue: <
          Entity extends keyof typeof self.suggestions,
          Field extends keyof typeof self.suggestions[Entity]
        >(
          entity: Entity,
          field: Field,
          value: any[]
        ) => {
          self.suggestions[entity][field] = value;
        },
        reset: () => {
          self.prescriptionFill = {} as any;
          self.prescription = {} as any;
          self.drug = {} as any;
          self.patient = {} as any;
          self.physician = {} as any;
          self.wasManualOrderFulfilled = null;
          self.suggestions = {} as any;
        },
        resetEntity: (key: 'prescriptionFill' | 'prescription' | 'drug' | 'patient' | 'physician') => {
          self[key] = {} as any;
        },
        resetSuggestions: (key: 'drug' | 'patient' | 'physician') => {
          self.suggestions[key] = {} as any;
        },
      }))
      .views((self) => ({
        get isLoadingKioskOptions() {
          return Boolean(store.models.kiosks.async.busy.list);
        },
        get isSubmitting() {
          return Boolean(store.models.prescriptionFills.async.busy.upsertWithReferences);
        },
        get doesPatientHaveIdentifier() {
          return Boolean(self.patient.email || self.patient.phoneNumber || self.patient.externalPharmacyId);
        },
        get doesPhysicianHaveIdentifier() {
          return Boolean(
            self.physician.NPI ||
              self.physician.SPI ||
              self.physician.stateLicenseNumber ||
              self.physician.externalPharmacyId
          );
        },
        constructKioskOption: (kiosk: any) => ({
          label: `${kiosk.name} (${kiosk.location.street1}, ${kiosk.location.city}, ${kiosk.location.state}, ${kiosk.location.zip})`,
          value: kiosk.id,
        }),
      }))
      .views((self) => ({
        get kioskOptions() {
          return self.kiosks.map((kiosk) => self.constructKioskOption(kiosk));
        },
        get canBeSubmitted() {
          const hasAllRequiredParams = Object.entries(PRESCRIPTION_FILL_PARAMS).every(([path, { required, regex }]) => {
            const value = _get(self, path);
            const satisfiesRegexConstraint = regex ? doesValueMatchRegex(regex, value) : true;
            if (!satisfiesRegexConstraint) {
              return false;
            }

            if (required) {
              return Boolean(value);
            } else {
              return true;
            }
          });

          return Boolean(
            hasAllRequiredParams &&
              self.doesPatientHaveIdentifier &&
              self.doesPhysicianHaveIdentifier &&
              self.kioskOption
          );
        },
      })),
    {}
  );

export default getPrescriptionFillManualEntryRouteStore;
