import React from 'react';
import { observer } from 'mobx-react';

import CrossIcon from '@clientCommon/library/assets/icons/cross.svg';

import './clearSectionButtonStyles.scss';

type Props = {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const ClearSectionButton = ({ onClick }: Props) => (
  <div className="c-ClearSectionButton" onClick={onClick}>
    <div className="text">Clear</div>
    <img className="icon" alt="Clear Section" src={CrossIcon} />
  </div>
);

export default observer(ClearSectionButton);
