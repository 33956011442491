import store from '@clientCommon/app/bootstrap/store';

import { DrugsDomain, DrugsQuery } from '../types';

const countDrugs = async (domain: DrugsDomain, query: DrugsQuery) => {
  const drugsCount = await store.models.drugs.async.count(query.filter, query);
  domain.setValue('entriesCount', drugsCount);

  return drugsCount;
};

export default countDrugs;
