import React from 'react';
import { observer } from 'mobx-react';

import TitledInput from '@clientCommon/library/components/titledInput';
import ContentScreen from '@clientCommon/library/components/contentScreen';

import { Entity, SUGGESTION_COMPONENT } from './constants';

import SearchIcon from '@clientCommon/library/assets/icons/search.svg';

import './autocompleteTitledInputStyles.scss';

type Props = {
  type: Entity;
  caption: string;
  validationRegex?: RegExp | RegExp[];
  placeholder?: string;
  value: string;
  onInputChange: (value: string) => void;
  required: boolean;
  isListingSuggestions?: boolean;
  suggestions: any[];
  onSuggestionClick: (suggestion: any) => void;
  clearSuggestions: () => void;
};

const AutocompleteTitledInput = ({
  type,
  caption,
  validationRegex,
  placeholder,
  value,
  onInputChange,
  required,
  isListingSuggestions = false,
  suggestions,
  onSuggestionClick,
  clearSuggestions,
}: Props) => {
  return (
    <div className="c-AutocompleteTitledInput">
      <ContentScreen active={isListingSuggestions} />
      <div className="autocomplete-caption-value-input-container">
        <div className="input-container">
          <TitledInput
            inputFieldClassNames={['max-width']}
            caption={caption}
            validationRegex={validationRegex}
            placeholder={placeholder}
            value={value}
            onChange={(value) => onInputChange(value)}
            required={required}
            onBlur={() => clearSuggestions()}
          />
          <img
            className="icon"
            alt="Searchable Field"
            title="Searchable field. Enter at least 3 characters to list suggestions."
            src={SearchIcon}
          />
        </div>
        {suggestions && !!suggestions.length && (
          <div className="suggestions">
            {suggestions.map((suggestion) => {
              const Suggestion = SUGGESTION_COMPONENT[type];
              return (
                <div
                  className="suggestion"
                  key={suggestion.id}
                  onMouseDown={(e) => e.preventDefault()} // prevent onBlur event firing before onClick
                  onClick={() => onSuggestionClick(suggestion)}
                >
                  <Suggestion suggestion={suggestion} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(AutocompleteTitledInput);
