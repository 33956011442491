import { NDC_REG_EXP, NDC_11_DIGIT_BILLING } from '@common/models/drugs/constants';

/**
 * NDCs need to be formatted to be a 5-4-2 format for billing.
 * This is done by adding to a prefixed 0 depending on the input type
 * Transformations that will occur will be:
 * 4-4-2 (9999-9999-99) -> 5-4-2 (09999-9999-99)
 * 5-3-2 (99999-999-99) -> 5-4-2 (99999-0999-99)
 * 5-4-1 (99999-9999-9) -> 5-4-2 (99999-9999-09)
 * 11 Digit NDC (99999999999) -> 5-4-2 (99999-9999-99)
 */
const formatNDC = (NDC: string) => {
  const match = NDC.match(NDC_REG_EXP) || NDC.match(NDC_11_DIGIT_BILLING);
  if (!match || !match.groups) {
    return;
  }

  const { labelerCode, productCode, packageSize } = match.groups;

  return `${labelerCode.padStart(5, '0')}-${productCode.padStart(4, '0')}-${packageSize.padStart(2, '0')}`;
};

export default formatNDC;
