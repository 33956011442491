export const MAX_TABLE_ENTRIES_PER_PAGE = 10;

export const COLUMNS = [
  {
    name: 'Name',
    fieldName: 'name',
    isSortable: true,
    width: '35%',
    render: ({ content }: any) => <div title={content.name}>{content.name}</div>,
  },
  {
    name: 'NDC',
    fieldName: 'NDC',
    isSortable: true,
    width: '20%',
    render: ({ content }: any) => <div title={content.NDC}>{content.NDC}</div>,
  },
  {
    name: 'UPC',
    fieldName: 'UPC',
    isSortable: true,
    width: '20%',
    render: ({ content }: any) => <div title={content.UPC}>{content.UPC}</div>,
  },
  {
    name: 'Strength',
    fieldName: 'strength',
    isSortable: false,
    width: '15%',
    render: ({ content }: any) => <div title={content.strength}>{content.strength}</div>,
  },
  {
    name: 'Quantity',
    fieldName: 'quantity',
    isSortable: false,
    width: '10%',
    render: ({ content }: any) => <div title={content.quantity}>{content.quantity}</div>,
  },
];
