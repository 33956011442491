import React from 'react';
import { observer } from 'mobx-react';

import ChevronRightIcon from './icons/chevronRight.svg';
import ChevronRightDisabledIcon from './icons/chevronRightDisabled.svg';

import './index.scss';

type Props = {
  currentPage: number;
  pagesCount: number;
  changePage: (newPage: number) => void;
};

const ArrowForward = ({ currentPage, pagesCount, changePage }: Props) => {
  const enabled = currentPage !== pagesCount;

  return (
    <img
      className={`${enabled ? 'c-ArrowForward' : 'c-ArrowForwardDisabled'}`}
      src={enabled ? ChevronRightIcon : ChevronRightDisabledIcon}
      onClick={() => changePage(currentPage + 1)}
    />
  );
};

export default observer(ArrowForward);
