import { types } from 'mobx-state-tree';

// Types
import { LoginDomainProps } from '../types';

// Store
import store from '@clientCommon/app/bootstrap/store';

export default ({ onLogin }: LoginDomainProps) =>
  types.optional(
    types
      .model({
        email: types.maybeNull(types.string),
        password: types.maybeNull(types.string),
        errorMessage: types.maybeNull(types.string),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends typeof self[K]>(key: K, value: V) => (self[key] = value),
      }))
      .actions((self) => ({
        login: async () => {
          if (!self.email || !self.password) return;

          store.models.users.async
            .login({
              email: self.email,
              password: self.password,
            })
            .then((result) => onLogin(result))
            .catch((err) => self.setValue('errorMessage', err.message));
        },
      })),
    {}
  );
