import { observer } from 'mobx-react';
import React from 'react';

// Types
import { ListItemContainerProps } from './types';

// Views
import ListItemView from './drillDownLayoutListItemView';

function ListItemContainer({ label, title, data, render, isActive, url, icons }: ListItemContainerProps) {
  return (
    <ListItemView label={label} title={title} data={data} render={render} isActive={isActive} url={url} icons={icons} />
  );
}

export default observer(ListItemContainer);
