import React from 'react';
import { observer } from 'mobx-react';
import { range as _range } from 'lodash';

type Props = {
  rangeFrom: number;
  rangeTo: number;
  currentPage: number;
  changePage: (newPage: number) => void;
};

const ContinuousSequence = ({ rangeFrom, rangeTo, currentPage, changePage }: Props) => (
  <>
    {_range(rangeFrom, rangeTo).map((pageNumber) => (
      <div
        key={pageNumber}
        className={pageNumber === currentPage ? 'number-active' : 'number'}
        onClick={() => changePage(pageNumber)}
      >
        {pageNumber}
      </div>
    ))}
  </>
);

export default observer(ContinuousSequence);
