import { flow } from 'mobx-state-tree';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Props = {
  email: string;
  password: string;
};

/**
 *
 */
export default flow(function* ({ email, password }: Props) {
  const response: { token: { access: string } } = yield http.post({
    url: `${API_BASE}${MODEL_ROUTE}/login`,
    body: {
      email,
      password,
    },
  });

  return response.token.access;
});
