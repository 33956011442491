import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

type Props = {
  text: string;
  customClassName?: string;
};

const Title = ({ text, customClassName }: Props) => (
  <div className={`c-TableLayoutTitle ${customClassName || ''}`}>
    <div className="title">{text}</div>
  </div>
);

export default observer(Title);
