import React from 'react';
import get from 'lodash/get';
import { observer } from 'mobx-react';

import store from '@clientCommon/app/bootstrap/store';

// Types
import { KiosksViewProps } from './types';

// Components
import DrillDownLayout from '@clientCommon/library/components/drillDownLayout';
import Containers from '@clientCommon/app/views/kiosks/containers';
import Inventory from './children/inventory';
import Storage from './children/storage';

// Assets
import OfflineIcon from '@clientCommon/library/assets/icons/alert-error.svg';
import OnlineIcon from '@clientCommon/library/assets/icons/online.svg';
import KioskIcon from '@clientCommon/library/assets/icons/kiosk-active.svg';
import TemperatureIcon from '@clientCommon/library/assets/icons/temperature.svg';

// Styles
import './kiosksStyles.scss';

function KioskView({ domain, filter, formatTemperature, onFilterChange, onFilterSearch }: KiosksViewProps) {
  return (
    <div className="c-KioskView">
      <DrillDownLayout
        title={'Kiosks'}
        list={{
          isBusy: domain.isLoadingKiosks,
          group: {
            key: 'locationId',
            label: { key: 'location.city' },
            sort: { key: 'location.city' },
          },
          item: {
            key: { path: 'id' },
            title: {
              fromData: (data) => data.name,
              right: {
                render: (data) => {
                  const latestStatusDate = data.latestStatus?.createdAt ? new Date(data.latestStatus?.createdAt) : null;
                  const isOnline = store.models.kiosks.getIsKioskOnline(latestStatusDate);
                  return (
                    <div className="kiosk-status">
                      <div className="kiosk-online">
                        <img
                          alt={`Kiosk ${isOnline ? 'Online' : 'Offline'}`}
                          src={isOnline ? OnlineIcon : OfflineIcon}
                        />
                      </div>
                      {data.latestStatus?.temperature && (
                        <div className="kiosk-temperature">
                          <span>
                            <img alt="Temperature" src={TemperatureIcon} />
                          </span>
                          <span>{formatTemperature(data.latestStatus.temperature)}</span>
                        </div>
                      )}
                    </div>
                  );
                },
              },
            },
            active: { fromData: (data) => Boolean(domain.activeKiosk && data.id === domain.activeKiosk.id) },
            url: { fromData: (data) => `/kiosks/${data.id}` },
            icons: [KioskIcon],
            render: ({ data: kiosk }) => (
              <div className="kiosk-data">
                <div className="healthcare-site-name">
                  {get(kiosk, 'kioskHealthcareSite.healthcareSite.name', 'N/A')}
                </div>
                <div className="location">{`${kiosk.location.street1}, ${kiosk.location.city}, ${kiosk.location.state}`}</div>
              </div>
            ),
          },
        }}
        dataList={domain.kioskList}
        filter={{
          term: filter,
          onChange: onFilterChange,
          onSearch: onFilterSearch,
        }}
        content={{
          isReady: Boolean(domain.activeKiosk),
          tabs: [
            {
              header: {
                title: domain.activeKiosk?.name || '',
              },
              content: {
                title: 'Inventory',
                component: <Inventory kioskId={domain.activeKiosk?.id || null} />,
              },
            },
            {
              header: {
                title: domain.activeKiosk?.name || '',
              },
              content: {
                title: 'Storage',
                component: <Storage kioskId={domain.activeKiosk?.id || null} />,
              },
            },
            {
              header: {
                title: domain.activeKiosk?.name || '',
              },
              content: {
                title: 'Containers',
                component: <Containers kioskId={domain.activeKiosk?.id || null} />,
              },
            },
          ],
        }}
      />
    </div>
  );
}

export default observer(KioskView);
