import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import DrugUpsertModalView from './drugUpsertModalView';

import formatNDC from '@common/utils/format/formatNDC';

import store from '@clientCommon/app/bootstrap/store';
import getDomain from './domain';

import { READABLE_ERRORS } from './constants';

import { DrugUpsertModalContainerProps } from './types';

const DrugUpsertModalContainer = ({ isOpen, activeDrug, onClose = () => {} }: DrugUpsertModalContainerProps) => {
  const domain = useMemo(() => getDomain({}).create(), []);

  useEffect(() => {
    if (isOpen) {
      const activelyModifiedDrug = activeDrug ? toJS(activeDrug) : ({} as any);
      domain.setActivelyModifiedDrug(activelyModifiedDrug);
    }
  }, [isOpen, activeDrug, domain]);

  const title = activeDrug ? activeDrug.name : 'New Drug';

  const tryFormatNDC = useCallback(() => {
    if (!domain || !domain.activelyModifiedDrug || !domain.activelyModifiedDrug.NDC) {
      return;
    }

    const formattedNDC = formatNDC(domain.activelyModifiedDrug.NDC);
    if (formattedNDC) {
      domain.setActivelyModifiedDrug({
        ...domain.activelyModifiedDrug,
        NDC: formattedNDC,
      });
    }
  }, [domain]);

  const closeModal = useCallback(() => {
    domain.reset();

    if (onClose) {
      return onClose();
    }
  }, [domain, onClose]);

  const upsertDrug = useCallback(async () => {
    if (!domain.activelyModifiedDrug) {
      return;
    }

    return store.models.drugs.async
      .upsert({
        id: domain.activelyModifiedDrug.id || null,
        name: domain.activelyModifiedDrug.name,
        NDC: domain.activelyModifiedDrug.NDC,
        quantity: domain.activelyModifiedDrug.quantity,
        strength: domain.activelyModifiedDrug.strength,
      })
      .then(() => closeModal())
      .catch((err) => {
        const { readableError } = READABLE_ERRORS.find(({ regex }) => err.text.match(regex).length) || {};
        return domain.setUpsertError(readableError || err.message || null);
      });
  }, [closeModal, domain]);

  return (
    <DrugUpsertModalView
      domain={domain}
      isOpen={isOpen}
      title={title}
      tryFormatNDC={tryFormatNDC}
      onClose={closeModal}
      upsertDrug={upsertDrug}
    />
  );
};

export default observer(DrugUpsertModalContainer);
