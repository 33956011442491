import React from 'react';
import { observer } from 'mobx-react';

import './drugSuggestionStyles.scss';

type Props = {
  suggestion: {
    name: string;
    NDC: string;
    quantity?: string;
  };
};

const DrugSuggestion = ({ suggestion }: Props) => {
  const { name, NDC, quantity } = suggestion;

  return (
    <div className="c-DrugSuggestion">
      <div className="name">{name}</div>
      <div className="detail">
        <span className="key">NDC:</span>
        {NDC}
      </div>
      <div className="detail">
        <span className="key">Quantity</span>
        {quantity || 'N/A'}
      </div>
    </div>
  );
};

export default observer(DrugSuggestion);
