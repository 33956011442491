import React from 'react';
import { observer } from 'mobx-react';

import constructClassName from './helpers/constructClassName';

import './modalRowStyles.scss';

type Props = {
  children: React.ReactNode;
  shouldBeCenteredHorizontally?: boolean;
  shouldBeSpaceBetween?: boolean;
  shouldBoundToBottom?: boolean;
};

const ModalRow = ({ children, shouldBeCenteredHorizontally, shouldBeSpaceBetween, shouldBoundToBottom }: Props) => {
  return (
    <div className={constructClassName({ shouldBeCenteredHorizontally, shouldBeSpaceBetween, shouldBoundToBottom })}>
      {children}
    </div>
  );
};

export default observer(ModalRow);
