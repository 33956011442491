import React, { HTMLInputTypeAttribute } from 'react';
import { observer } from 'mobx-react';

import doesValueMatchRegex from '@clientCommon/library/utils/doesValueMatchRegex';

import './titledInputStyles.scss';

type Props = {
  type?: HTMLInputTypeAttribute;
  className?: string;
  inputFieldClassNames?: string[];
  placeholder?: string;
  caption: string;
  value: string | number | null;
  validationRegex?: RegExp | RegExp[];
  validationError?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  required?: boolean;
};

const TitledInput = ({
  caption,
  onChange,
  onBlur,
  type = 'text',
  validationRegex,
  validationError,
  value,
  className,
  inputFieldClassNames = [],
  placeholder,
  required = false,
}: Props) => {
  const inputFieldClassName = inputFieldClassNames.join(', ');
  const isCurrentValueValid = validationRegex ? doesValueMatchRegex(validationRegex, value) : true;

  return (
    <div className={`c-TitledInput ${className}`}>
      <input
        type={type}
        placeholder={placeholder}
        className={`${
          !isCurrentValueValid || (required && !value) ? 'input-required' : 'input'
        } ${inputFieldClassName}`}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
      />
      {!isCurrentValueValid && validationError && <div className="validation-error">{validationError}</div>}
      <div className="caption">{caption}</div>
    </div>
  );
};

export default observer(TitledInput);
