import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Views
import Header from './views/header';
import Navbar from './views/navbar';

// Children
import Drugs from './children/drugs';
import Kiosks from './children/kiosks';
import Lots from './children/lots';
import PrescriptionFillManualEntry from '@clientCommon/app/views/prescriptionFillManualEntry';

// Styles
import './appFrameStyles.scss';

function AppFrameView() {
  return (
    <div className="c-AppFrameView">
      <Header />
      <div className="content">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="primary-content">
          <Routes>
            <Route path="/drugs" element={<Drugs />} />
            <Route path="/kiosks/:kioskId" element={<Kiosks />} />
            <Route path="/kiosks" element={<Kiosks />} />
            <Route path="/lots/:lotId" element={<Lots />} />
            <Route path="/lots" element={<Lots />} />
            <Route path="/prescription-fill-manual-entry" element={<PrescriptionFillManualEntry />} />
            <Route path="*" element={<Navigate to="/kiosks" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default observer(AppFrameView);
