import { observer } from 'mobx-react';
import React from 'react';

// Types
import { DrillDownLayoutViewProps } from './types';

// Components
import List from './components/list';
import Content from './components/content/drillDownLayoutContentContaner';

// Styles
import './drillDownLayoutStyles.scss';

function DrillDownLayoutView({ title, dataList, filter, list, content }: DrillDownLayoutViewProps) {
  return (
    <div className="c-DrillDownLayoutView">
      <List
        title={title}
        dataList={dataList}
        filter={filter}
        item={list.item}
        group={list.group}
        isBusy={list.isBusy}
      />
      <Content isReady={content.isReady} tabs={content.tabs} />
    </div>
  );
}

export default observer(DrillDownLayoutView);
