const parseDbDateOfBirth = (DOB: string) => {
  if (!DOB.includes('-')) {
    return;
  }

  const [year, month, day] = DOB.split('-');
  return `${month}/${day}/${year}`;
};

export default parseDbDateOfBirth;
