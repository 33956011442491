import { flow } from 'mobx-state-tree';

// Types
import { Patient } from '@common/models/patients/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Filter = {
  firstName?: string;
  lastName?: string;
  externalPharmacyId?: string;
};

export default flow(function* (filter: Filter = {}) {
  const response: { data: Patient[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
    query: { filter },
  });

  return response.data;
});
