import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

type Props = {
  count: number;
  caption: string;
};

const EntriesCount = ({ count, caption }: Props) => (
  <div className="c-TableLayoutTableHeaderEntriesCount">
    <div className="count">{count}</div>
    <div className="caption">{caption}</div>
  </div>
);

export default observer(EntriesCount);
