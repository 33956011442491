import { types } from 'mobx-state-tree';
import _ from 'lodash';

import store from '@clientCommon/app/bootstrap/store';

import { LotDecorated } from '@common/models/lots/types';
import { LotsDomainProps } from '../types';

const lotsRouteStore = ({}: LotsDomainProps) =>
  types.optional(
    types
      .model({
        activeLotId: types.maybeNull(types.string),
        lots: types.map(types.frozen<LotDecorated>()),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends typeof self[K]>(key: K, value: V) => (self[key] = value),
        setLots: (lots: LotDecorated[]) => {
          self.lots.replace(_.keyBy(lots, 'id'));
        },
        addLots: (lots: LotDecorated[]) => {
          self.lots.merge(_.keyBy(lots, 'id'));
        },
        reset: () => {
          self.activeLotId = null;
          self.lots = {} as any;
        },
      }))
      .actions((self) => ({}))
      .views((self) => ({
        get isLoadingLots() {
          return Boolean(store.models.lots.async.busy.list);
        },
        get activeLot() {
          if (self.activeLotId) {
            return self.lots.get(self.activeLotId);
          } else {
            return null;
          }
        },
        get lotsList() {
          return Array.from(self.lots.values());
        },
      })),
    {}
  );

export default lotsRouteStore;
