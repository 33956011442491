import { observer } from 'mobx-react';
import React from 'react';
import get from 'lodash/get';

// Types
import { ListViewProps } from './types';

// Components
import Header from './components/header';
import ListItem from './components/listItem';
import ContentScreen from '@clientCommon/library/components/contentScreen';

// Styles
import './drillDownLayoutListStyles.scss';

function Group({ dataList, item }: Pick<ListViewProps, 'dataList' | 'item'>) {
  return (
    <>
      {dataList.map((data) => {
        const key = item.key.path ? get(data, item.key.path) : item.key?.fromData?.(data);

        return (
          <ListItem
            key={key}
            title={item.title}
            label={item.label}
            data={data}
            render={item.render}
            isActive={item.active.fromData(data)}
            url={item.url.fromData(data)}
            icons={item.icons}
          />
        );
      })}
    </>
  );
}

function ListView({ title, dataList, filter, item, grouped, isBusy }: ListViewProps) {
  return (
    <div className="c-ListView">
      <Header title={title} filter={filter} />
      <div className="items-pane">
        <ContentScreen active={isBusy} />
        {grouped ? (
          grouped.map((grouping) => {
            return (
              <div className="group" key={grouping.keyValue}>
                <div className="group-title-container">
                  <div className="group-title">{grouping.labelKeyValue}</div>
                  <div className="horizontal-rule-container">
                    <hr />
                  </div>
                </div>
                <div>
                  <Group item={item} dataList={grouping.list} />
                </div>
              </div>
            );
          })
        ) : (
          <Group item={item} dataList={dataList} />
        )}
      </div>
    </div>
  );
}

export default observer(ListView);
