import React, { useCallback } from 'react';

import './infiniteScrollStyles.scss';

type Props = {
  children: React.ReactNode;
  onBottomThreshold?: () => void;
  onTopThreshold?: () => void;
};

const InfiniteScroll = ({ children, onBottomThreshold, onTopThreshold }: Props) => {
  const onScrollEvent = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (
        onBottomThreshold &&
        (event.target as HTMLElement).scrollTop + (event.target as HTMLElement).clientHeight >=
          (event.target as HTMLElement).scrollHeight
      ) {
        onBottomThreshold();
      }

      if (onTopThreshold && (event.target as HTMLElement).scrollTop === 0) {
        onTopThreshold();
      }
    },
    [onBottomThreshold, onTopThreshold]
  );

  return (
    <div className="c-InfiniteScroll" onScroll={(e) => onScrollEvent(e)}>
      {children}
    </div>
  );
};

export default InfiniteScroll;
