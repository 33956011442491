import { flow } from 'mobx-state-tree';

// Types
import { KioskDecorated } from '@common/models/kiosks/types';

// Utils
import http from '@clientCommon/app/bootstrap/http';

// Constants
import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

// Stores
import store from '@clientCommon/app/bootstrap/store';

type Query = {
  filter: {
    name: string;
  };
};

/**
 *
 */
export default flow(function* (
  filterValue?: string,
  { save }: { save?: boolean; } = {},
) {
  const query = {} as Query;
  if (filterValue) {
    query.filter = {
      name: filterValue,
    };
  }

  const response: { data: KioskDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
    query,
  });

  const kiosks = response.data;

  if (save !== false) store.models.kiosks.mergeKiosks(kiosks);

  return kiosks;
});
