import { types } from 'mobx-state-tree';

import doesValueMatchRegex from '@clientCommon/library/utils/doesValueMatchRegex';

import { NDC_11_DIGIT_BILLING, NDC_REG_EXP } from '@common/models/drugs/constants';

import { Drug } from '@common/models/drugs/types';
import { DrugUpsertModalDomainProps } from '../types';

const getDrugUpsertModalRouteStore = (props: DrugUpsertModalDomainProps) =>
  types.optional(
    types
      .model({
        activelyModifiedDrug: types.maybeNull(types.frozen<Drug>()),
        upsertError: types.maybeNull(types.string),
      })
      .actions((self) => ({
        setUpsertError: (value: string | null) => (self.upsertError = value),
      }))
      .actions((self) => ({
        setActivelyModifiedDrug: (drug: Drug) => {
          self.activelyModifiedDrug = drug;
          self.setUpsertError(null);
        },
      }))
      .actions((self) => ({
        reset: () => {
          self.activelyModifiedDrug = null;
          self.upsertError = null;
        },
      }))
      .views((self) => ({
        get isNDCValid() {
          const NDC = self.activelyModifiedDrug?.NDC;
          if (!NDC) {
            return false;
          }

          return doesValueMatchRegex([NDC_REG_EXP, NDC_11_DIGIT_BILLING], NDC);
        },
      }))
      .views((self) => ({
        get canBeSubmitted() {
          return self.activelyModifiedDrug && self.activelyModifiedDrug.name && self.isNDCValid;
        },
      })),
    {}
  );

export default getDrugUpsertModalRouteStore;
