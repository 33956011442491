import React from 'react';
import { observer } from 'mobx-react';

import ChevronLeftIcon from './icons/chevronLeft.svg';
import ChevronLeftDisabledIcon from './icons/chevronLeftDisabled.svg';

import './index.scss';

type Props = {
  currentPage: number;
  changePage: (newPage: number) => void;
};

const ArrowBack = ({ currentPage, changePage }: Props) => {
  const enabled = currentPage !== 1;

  return (
    <img
      className={`${enabled ? 'c-ArrowBack' : 'c-ArrowBackDisabled'}`}
      src={enabled ? ChevronLeftIcon : ChevronLeftDisabledIcon}
      onClick={() => changePage(currentPage - 1)}
    />
  );
};

export default observer(ArrowBack);
