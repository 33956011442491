import { observer } from 'mobx-react';
import React from 'react';

// Types
import { HeaderContainerProps } from './types';

// Views
import HeaderView from './drillDownLayoutListHeaderView';

function HeaderContainer({ title, filter }: HeaderContainerProps) {
  return <HeaderView title={title} filter={filter} />;
}

export default observer(HeaderContainer);
