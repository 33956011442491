import React from 'react';
import { useLocation } from 'react-router-dom';

import NavbarView from './navbarView';

const NavbarContainer = () => {
  const { pathname } = useLocation();

  const isActiveRoute = (route: string) => pathname.startsWith(route);

  return <NavbarView isActiveRoute={isActiveRoute} />;
};

export default NavbarContainer;
