import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import TitledInput from '@clientCommon/library/components/titledInput';
import Modal from '@clientCommon/library/components/modal';
import ModalEditingAreas from '@clientCommon/library/components/modal/layout/editingAreas';
import ModalEditingArea from '@clientCommon/library/components/modal/layout/editingAreas/components/editingArea';
import ModalRow from '@clientCommon/library/components/modal/layout/row';
import ModalButton from '@clientCommon/library/components/modal/layout/button';

import { NDC_11_DIGIT_BILLING, NDC_REG_EXP } from '@common/models/drugs/constants';

import { DrugUpsertModalViewProps } from './types';

import './drugUpsertModalViewStyles.scss';

const DrugUpdateModalView = ({
  domain,
  isOpen,
  title,
  tryFormatNDC,
  upsertDrug,
  onClose,
}: DrugUpsertModalViewProps) => {
  return (
    <Modal
      modalStyles={{ height: '50%', width: '50%' }}
      isOpen={isOpen}
      title={title}
      shouldCloseOnOverlayClick={false}
      close={onClose}
    >
      <ModalEditingAreas>
        <ModalEditingArea>
          <ModalRow>
            <TitledInput
              inputFieldClassNames={['max-width']}
              caption="Name"
              value={_get(domain, 'activelyModifiedDrug.name')}
              onChange={(value: string) =>
                domain.activelyModifiedDrug &&
                domain.setActivelyModifiedDrug({ ...domain.activelyModifiedDrug, name: value })
              }
            />
          </ModalRow>
          <ModalRow>
            <TitledInput
              inputFieldClassNames={['max-width']}
              caption="NDC"
              value={_get(domain, 'activelyModifiedDrug.NDC')}
              validationRegex={[NDC_11_DIGIT_BILLING, NDC_REG_EXP]}
              validationError="xxxx(x)-xxx(x)-x(x) or xxxxxxxxxxx"
              onChange={(value: string) =>
                domain.activelyModifiedDrug &&
                domain.setActivelyModifiedDrug({ ...domain.activelyModifiedDrug, NDC: value })
              }
              onBlur={() => tryFormatNDC()}
            />
          </ModalRow>
        </ModalEditingArea>
        <ModalEditingArea>
          <ModalRow>
            <TitledInput
              inputFieldClassNames={['max-width']}
              caption="Strength"
              value={_get(domain, 'activelyModifiedDrug.strength')}
              onChange={(value: string) =>
                domain.activelyModifiedDrug &&
                domain.setActivelyModifiedDrug({ ...domain.activelyModifiedDrug, strength: value })
              }
            />
          </ModalRow>
          <ModalRow>
            <TitledInput
              inputFieldClassNames={['max-width']}
              caption="Quantity"
              value={_get(domain, 'activelyModifiedDrug.quantity')}
              onChange={(value: string) =>
                domain.activelyModifiedDrug &&
                domain.setActivelyModifiedDrug({ ...domain.activelyModifiedDrug, quantity: value })
              }
            />
          </ModalRow>
        </ModalEditingArea>
      </ModalEditingAreas>
      <ModalRow shouldBoundToBottom={true}>
        <ModalButton color="blue" text="Save" isDisabled={!domain.canBeSubmitted} onClick={upsertDrug} />
        {domain.upsertError && <div className="drug-upsert-error">{domain.upsertError}</div>}
      </ModalRow>
    </Modal>
  );
};

export default observer(DrugUpdateModalView);
