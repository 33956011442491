import { observer } from 'mobx-react';
import React from 'react';

// Types
import { DrillDownLayoutContainerProps } from './types';

// Views
import DrillDownLayoutView from './drillDownLayoutView';

function DrillDownLayoutContainer({ title, dataList, filter, list, content }: DrillDownLayoutContainerProps) {
  return <DrillDownLayoutView title={title} dataList={dataList} filter={filter} list={list} content={content} />;
}

export default observer(DrillDownLayoutContainer);
