import { flow } from 'mobx-state-tree';

import { LotDecorated } from '@common/models/lots/types';

import http from '@clientCommon/app/bootstrap/http';

import { API_BASE } from '@clientCommon/app/constants';
import { MODEL_ROUTE } from '../../constants';

type Query = {
  number?: string;
};

export default flow(function* (filterValue?: string) {
  const query = {} as Query;
  if (filterValue) {
    query.number = filterValue;
  }

  const response: { data: LotDecorated[] } = yield http.get({
    url: `${API_BASE}${MODEL_ROUTE}`,
    query,
  });

  return response.data;
});
