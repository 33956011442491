import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { keyBy as _keyBy } from 'lodash';

import InfoView from './lotInfoView';

import store from '@clientCommon/app/bootstrap/store';

import { DrugDecorated } from '@common/models/drugs/types';
import { LotDrugRecall } from '@clientCommon/@common/models/lotDrugRecalls/types';
import { LotInfoContainerProps } from './types';

const InfoContainer = ({ activeLot }: LotInfoContainerProps) => {
  const [drugsByLotId, setDrugsByLotId] = useState({} as { [lotId in string]: DrugDecorated });
  const [recallDrugIds, setRecallDrugIds] = useState(new Set([] as string[]));

  const drugsList = useMemo(() => Array.from(Object.values(drugsByLotId)), [drugsByLotId]);

  const listDrugs = useCallback(async () => {
    const drugs = await store.models.drugs.async.listByLotId(activeLot.id);
    setDrugsByLotId(_keyBy(drugs, 'id'));
  }, [activeLot.id]);

  useEffect(() => {
    listDrugs();
  }, [listDrugs]);

  const unrecalledDrugsCount = useMemo(() => {
    const recalledDrugsCount = drugsList.reduce((count, drug) => count + ~~!!drug.lotDrugRecall, 0);
    return drugsList.length - recalledDrugsCount;
  }, [drugsList]);

  const addRecallDrugId = useCallback(
    (drugId: string | string[]) => {
      const drugIds = Array.isArray(drugId) ? drugId : [drugId];
      setRecallDrugIds(new Set([...Array.from(recallDrugIds), ...drugIds]));
    },
    [recallDrugIds]
  );

  const clearRecallDrugIds = useCallback(() => {
    setRecallDrugIds(new Set([]));
  }, []);

  const recallSelectedDrugs = useCallback(async () => {
    const recallDrugPromises: Promise<LotDrugRecall>[] = [];
    recallDrugIds.forEach((drugId) => {
      recallDrugPromises.push(store.models.lotDrugRecalls.async.create(drugId, activeLot.id));
    });
    await Promise.all(recallDrugPromises);
    setRecallDrugIds(new Set([]));
    return listDrugs();
  }, [activeLot.id, listDrugs, recallDrugIds]);

  return (
    <InfoView
      activeLot={activeLot}
      isBusy={Boolean(store.models.drugs.async.busy.listByLotId || store.models.lotDrugRecalls.async.busy.create)}
      drugsByLotId={drugsByLotId}
      drugsList={drugsList}
      unrecalledDrugsCount={unrecalledDrugsCount}
      recallDrugIds={Array.from(recallDrugIds)}
      addRecallDrugId={addRecallDrugId}
      clearRecallDrugIds={clearRecallDrugIds}
      recallSelectedDrugs={recallSelectedDrugs}
    />
  );
};

export default observer(InfoContainer);
