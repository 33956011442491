export const API_BASE =
  process.env.REACT_APP_MEDIFRIEND === 'production'
    ? 'https://api.medifriendrx.com'
    : process.env.REACT_APP_MEDIFRIEND === 'staging'
    ? 'https://api.staging.medifriendrx.com'
    : 'http://localhost:8080';

const UUID_CHAR = '0-9A-Fa-f';

export const RE = Object.freeze({
  UUID: `[${UUID_CHAR}]{8}\-[${UUID_CHAR}]{4}\-[${UUID_CHAR}]{4}\-[${UUID_CHAR}]{4}\-[${UUID_CHAR}]{12}`,
});

export const ELECTRON_API_DATA_CHANNEL = 'appChannel';
