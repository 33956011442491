import { types } from 'mobx-state-tree';
import _ from 'lodash';

import store from '@clientCommon/app/bootstrap/store';

// Constants
import { KIOSK_TAB, KIOSK_TABS } from '../constants';

// Types
import { Kiosk } from '@common/models/kiosks/types';
import { KiosksDomainProps } from '../types';

const kiosksRouteStore = (props: KiosksDomainProps) =>
  types.optional(
    types
      .model({
        activeKioskId: types.maybeNull(types.string),
        kiosks: types.map(types.frozen<Kiosk>()),
        activeTab: types.optional(types.enumeration(KIOSK_TABS), KIOSK_TAB.INVENTORY),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends typeof self[K]>(key: K, value: V) => (self[key] = value),
        setKiosks: (kiosks: Kiosk[]) => {
          self.kiosks.replace(_.keyBy(kiosks, 'id'));
        },
        addKiosks: (kiosks: Kiosk[]) => {
          self.kiosks.merge(_.keyBy(kiosks, 'id'));
        },
      }))
      .actions((self) => ({}))
      .views((self) => ({
        get isLoadingKiosks() {
          return Boolean(store.models.kiosks.async.busy.list);
        },
        get activeKiosk() {
          if (self.activeKioskId) {
            return self.kiosks.get(self.activeKioskId);
          } else {
            return null;
          }
        },
        get kioskList() {
          return Array.from(self.kiosks.values());
        },
      })),
    {}
  );

export default kiosksRouteStore;
