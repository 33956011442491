import React from 'react';
import { observer } from 'mobx-react';

import InterruptedSequence from './components/interruptedSequence';
import ContinuousSequence from './components/continuousSequence';

import { detectRanges } from './helpers';

import './index.scss';

type Props = {
  showingDots: boolean;
  currentPage: number;
  pagesCount: number;
  changePage: (newPage: number) => void;
  maxPagesVisible: number;
};

const Pages = ({ showingDots, currentPage, pagesCount, changePage, maxPagesVisible }: Props) => {
  const { rangeFrom, rangeTo } = detectRanges({ showingDots, currentPage, pagesCount, maxPagesVisible });

  return (
    <div className="pagination-numbers">
      {showingDots ? (
        <InterruptedSequence
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          currentPage={currentPage}
          pagesCount={pagesCount}
          changePage={changePage}
        />
      ) : (
        <ContinuousSequence rangeFrom={rangeFrom} rangeTo={rangeTo} currentPage={currentPage} changePage={changePage} />
      )}
    </div>
  );
};

export default observer(Pages);
