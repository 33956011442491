import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import LotsView from './lotsView';

import store from '@clientCommon/app/bootstrap/store';
import getDomain from './domain';

const LotsContainer = () => {
  const domain = useMemo(() => getDomain({}).create(), []);

  const [filter, setFilter] = useState('');

  const navigate = useNavigate();
  const { lotId } = useParams();

  const listLots = useCallback(async (filterValue?: string) => {
    return store.models.lots.async.list(filterValue);
  }, []);

  const navigateToLot = useCallback(
    (lotId: string | null) => {
      if (!domain.lots.size) {
        return;
      }

      if (lotId && domain.lots.get(lotId)) {
        navigate(`/lots/${lotId}`);
      } else {
        navigate(`/lots/${domain.lotsList[0].id}`);
      }
    },
    [domain.lots, domain.lotsList, navigate]
  );

  const onFilterChange = useCallback(
    async (filterValue: string) => {
      setFilter(filterValue);
      const lots = await listLots(filterValue);
      domain.setLots(lots);
    },
    [domain, listLots]
  );

  useEffect(() => {
    (async () => {
      const lots = await listLots();
      domain.addLots(lots);
    })();
  }, []);

  useEffect(() => {
    const activeLotId = lotId || null;
    domain.setValue('activeLotId', activeLotId);
    navigateToLot(activeLotId);
  }, [domain, lotId, navigateToLot]);

  return <LotsView domain={domain} filter={filter} onFilterChange={onFilterChange} />;
};

export default observer(LotsContainer);
