import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from '@clientCommon/library/components/contentScreen';
import SearchInput from '@clientCommon/library/components/searchInput';
import SmartTable from '@clientCommon/library/components/smartTable';

import { LotPrescriptionFillsViewProps } from './types';

import './lotPrescriptionFillsStyles.scss';

const PrescriptionFillsView = ({
  isBusy,
  onPrescriptionFillSearch,
  prescriptionFillsList,
}: LotPrescriptionFillsViewProps) => (
  <div className="c-LotPrescrptionFills">
    <ContentScreen active={isBusy} />
    <div className="search">
      <SearchInput onSearch={onPrescriptionFillSearch} />
    </div>
    {prescriptionFillsList.length ? (
      <SmartTable
        loading={isBusy}
        rows={prescriptionFillsList}
        columns={[
          {
            name: 'Drug',
            fieldName: 'drug.name',
            isSortable: false,
            width: '35%',
            render: ({ content }) => (
              <div title={content.drug.name}>
                {content.drug.name}
                <div>{content.drug.NDC}</div>
              </div>
            ),
          },
          {
            name: 'Creation Date',
            fieldName: 'createdAt',
            isSortable: false,
            width: '15%',
            render: ({ content }) => <div title={content.creationDate}>{content.creationDate}</div>,
          },
          {
            name: 'Patient',
            fieldName: 'patient.name',
            isSortable: false,
            width: '35%',
            render: ({ content }) => (
              <div className="patient">
                <div className="name" title={content.name}>
                  {content.name}
                </div>
                <div className="pharmacy-id" title={content.name}>
                  <span>Pharmacy ID:</span>
                  <span> {content.externalPharmacyId}</span>
                </div>
              </div>
            ),
          },
          {
            name: 'Phone #',
            fieldName: 'patientPhoneNumber',
            isSortable: false,
            width: '15%',
            render: ({ content }) => <div title={content.phoneNumber}>{content.phoneNumber}</div>,
          },
        ]}
      />
    ) : (
      <div className="no-prescription-fills-text">No prescriptions found.</div>
    )}
  </div>
);

export default observer(PrescriptionFillsView);
