import React from 'react';
import { observer } from 'mobx-react';
import { range as _range } from 'lodash';

type Props = {
  rangeFrom: number;
  rangeTo: number;
  currentPage: number;
  pagesCount: number;
  changePage: (newPage: number) => void;
};

const InterruptedSequence = ({ rangeFrom, rangeTo, currentPage, pagesCount, changePage }: Props) => (
  <>
    {_range(rangeFrom, rangeTo).map((pageNumber) => (
      <div
        key={pageNumber}
        className={pageNumber === currentPage ? 'number-active' : 'number'}
        onClick={() => changePage(pageNumber)}
      >
        {pageNumber}
      </div>
    ))}
    <div className="dots">...</div>
    <div
      key={pagesCount}
      className={pagesCount === currentPage ? 'number-active' : 'number'}
      onClick={() => changePage(pagesCount)}
    >
      {pagesCount}
    </div>
  </>
);

export default observer(InterruptedSequence);
