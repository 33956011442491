import React from 'react';
import { observer } from 'mobx-react';

import store from '@clientCommon/app/bootstrap/store';

// Routes
import AppFrame from './children/appFrame';
import Login from '@clientCommon/app/singletons/login';
import { BrowserRouter, Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';
import ContentGate from '@clientCommon/library/components/contentGate/index.web.p';

// Styles
import './appStyles.scss';

function AppViewWrapper() {
  return (
    <BrowserRouter>
      <AppViewObserved />
    </BrowserRouter>
  );
}

function AppView() {
  console.log('Pharmacy Technician Portal.', store, store.global.auth.hasCheckedLocalStorage);

  const navigate = useNavigate();

  return (
    <ContentGate className="c-RootView" active={!store.global.auth.hasCheckedLocalStorage} fadeDuration={500}>
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              onLogin={() => {
                navigate('/');
              }}
            />
          }
        />

        {/* <Route path='/reset-password' element={<ResetPasswordContainer/>} />
      <Route path='/change-password' element={<ChangePasswordContainer/>} /> */}

        <Route path="*" element={store.global.auth.accessToken ? <AppFrame /> : <Navigate to="/login" />} />
      </Routes>
    </ContentGate>
  );
  // return <div>
  //   <Login
  //     onLogin={(...args) => {
  //       console.log('LOGIN RESPONSE', ...args);
  //     }}
  //   />
  // </div>
}

const AppViewObserved = observer(AppView);

export default observer(AppViewWrapper);
