import React from 'react';
import { observer } from 'mobx-react';

// Hooks
import useAuth from '@clientCommon/app/hooks/useAuth';

// Views
import AppView from './appView';


function AppContainer() {
  useAuth();

  return <AppView />;
}

export default observer(AppContainer);
