import { types } from 'mobx-state-tree';
import { flatMap as _flatMap, isNumber as _isNumber, keyBy as _keyBy, orderBy as _orderBy } from 'lodash';

import store from '@clientCommon/app/bootstrap/store';

import { SortingOrder } from '@clientCommon/library/components/smartTable/types';
import { GetComputedInventoryResponse } from '@clientCommon/app/models/kiosks/asyncActions/getComputedInventory';

const kioskInventoryRouteStore = () =>
  types.optional(
    types
      .model({
        inventory: types.frozen<GetComputedInventoryResponse | null>(),
        filter: types.optional(types.string, ''),
        sortingColumn: types.optional(
          types.model({
            fieldName: types.optional(types.string, 'name'),
            sortingOrder: types.optional(types.enumeration<'asc' | 'desc'>(['asc', 'desc']), 'asc'),
          }),
          {}
        ),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends typeof self[K]>(key: K, value: V) => (self[key] = value),
        setSortingColumn: (fieldName: string, sortingOrder: SortingOrder) =>
          (self.sortingColumn = { fieldName, sortingOrder }),
        reset: () => {
          self.inventory = null;
          self.filter = '';
          self.sortingColumn = {
            fieldName: 'name',
            sortingOrder: 'asc',
          };
        },
      }))
      .views((self) => ({
        get isBusy() {
          return Boolean(store.models.kiosks.async.busy.getComputedInventory);
        },
        get computedInventory() {
          if (!self.inventory) {
            return null;
          }

          return self.inventory.kiosk.computed_inventory;
        },
        get inventoryDrugsById() {
          if (!self.inventory) {
            return null;
          }

          return _keyBy(self.inventory.drugs, 'id');
        },
      }))
      .views((self) => ({
        isDrugStockLow: (drugId: string) => {
          if (!self.computedInventory || !self.inventoryDrugsById) {
            return false;
          }

          const totalCount = self.computedInventory.byDrugId[drugId]?.count?.total;
          const lowContainersCountThreshold = self.inventoryDrugsById[drugId].kioskDrugThreshold?.lowContainersCount;
          if (!_isNumber(totalCount) || !_isNumber(lowContainersCountThreshold)) {
            return false;
          }

          return totalCount <= lowContainersCountThreshold;
        },
      }))
      .views((self) => ({
        get forTable() {
          if (!self.inventory || !self.computedInventory || !self.inventoryDrugsById) {
            return [];
          }

          const drugIds = Object.keys(self.computedInventory.byQuantity.byDrugId);
          let forTable = _flatMap(drugIds, (drugId) => {
            if (!self.computedInventory) {
              return [];
            }

            const drugQuantityVariants = self.computedInventory.byQuantity.byDrugId[drugId];

            return Object.keys(drugQuantityVariants).map((quantity) => {
              const drug = self.inventoryDrugsById ? self.inventoryDrugsById[drugId] : ({} as any);
              const drugQuantityVariant = drugQuantityVariants[quantity];
              const { count } = drugQuantityVariant;
              return {
                id: drug.id,
                name: drug.name,
                NDC: drug.NDC || 'N/A',
                count: {
                  ...count,
                  unassignable: count.total - count.assignable,
                },
                quantity,
                isLowStock: self.isDrugStockLow(drugId),
                strength: drug.strength || drug.activeNumeratorStrength,
              };
            });
          });

          if (self.filter) {
            const regex = new RegExp(self.filter, 'i');
            forTable = forTable.filter((drug) => regex.test(drug.name) || regex.test(drug.NDC));
          }

          return _orderBy(forTable, [self.sortingColumn.fieldName], [self.sortingColumn.sortingOrder]);
        },
      })),
    {} as any
  );

export default kioskInventoryRouteStore;
