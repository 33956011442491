import { types } from 'mobx-state-tree';

import store from '@clientCommon/app/bootstrap/store';

import { DrugsDomainProps } from '../types';
import { Drug } from '@common/models/drugs/types';
import { SortingOrder } from '@clientCommon/library/components/smartTable/types';

const getDrugsRouteStore = (props: DrugsDomainProps) =>
  types.optional(
    types
      .model({
        drugsById: types.optional(types.map(types.frozen<Drug>()), {}),
        activeDrugId: types.maybeNull(types.string),
        entriesCount: types.optional(types.number, 0),
        currentPage: types.optional(types.number, 1),
        filterValue: types.optional(types.string, ''),
        sortingColumn: types.optional(
          types.model({
            fieldName: types.optional(types.enumeration(['name', 'NDC', 'UPC']), 'name'),
            sortingOrder: types.optional(types.enumeration<SortingOrder>(['asc', 'desc']), 'asc'),
          }),
          {}
        ),
        showingDrugUpsertModal: types.optional(types.boolean, false),
      })
      .actions((self) => ({
        setValue: <K extends keyof typeof self, V extends typeof self[K]>(key: K, value: V) => (self[key] = value),
        reset: () => {
          self.drugsById = {} as any;
          self.entriesCount = 0;
          self.filterValue = '';
          self.activeDrugId = null;
          self.sortingColumn = {} as any;
          self.currentPage = 1;
          self.showingDrugUpsertModal = false;
        },
      }))
      .views((self) => ({
        get drugsList() {
          return Array.from(self.drugsById.values());
        },
      }))
      .views((self) => ({
        get loading() {
          return !!(store.models.drugs.async.busy.list || store.models.drugs.async.busy.count);
        },
        get activeDrug() {
          if (self.activeDrugId) {
            return self.drugsById.get(self.activeDrugId) || null;
          } else {
            return null;
          }
        },
        get forTable() {
          return self.drugsList.map((drug) => ({
            id: drug.id,
            name: drug.name || 'N/A',
            NDC: drug.NDC || 'N/A',
            UPC: drug.UPC || 'N/A',
            strength: drug.strength || 'N/A',
            quantity: drug.quantity || 'N/A',
          }));
        },
      })),
    {}
  );

export default getDrugsRouteStore;
