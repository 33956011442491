import React from 'react';
import { observer } from 'mobx-react';

import './physicianSuggestionStyles.scss';

type Props = {
  suggestion: {
    firstName: string;
    lastName: string;
    NPI: string;
    SPI: string;
    stateLicenseNumber: string;
    externalPharmacyId: string;
  };
};

const PhysicianSuggestion = ({ suggestion }: Props) => {
  const { firstName, lastName, NPI, SPI, stateLicenseNumber, externalPharmacyId } = suggestion;

  return (
    <div className="c-PhysicianSuggestion">
      <div className="name">{`${firstName} ${lastName}`}</div>
      <div className="detail">
        <span className="key">NPI:</span>
        {NPI}
      </div>
      <div className="detail">
        <span className="key">SPI:</span>
        {SPI}
      </div>
      <div className="detail">
        <span className="key">State License #:</span>
        {stateLicenseNumber}
      </div>
      <div className="detail">
        <span className="key">Unique Identifier:</span>
        {externalPharmacyId}
      </div>
    </div>
  );
};

export default observer(PhysicianSuggestion);
