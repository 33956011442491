import { useEffect, useRef } from 'react';

// Types
import { User } from '@clientCommon/@common/models/users/types';

import store from '@clientCommon/app/bootstrap/store';

type Props = {
  requireAdmin?: boolean;
};

export default function useAuth({
  requireAdmin,
}: Props = {}) {
  const { accessToken, accessTokens } = store.global.auth;
  const token = accessTokens.get('accessToken');

  useEffect(() => {
    if (store.global.auth.hasCheckedLocalStorage) return;
    store.global.auth.getTokenFromLocalStorage(); // TODO remove this
    store.global.auth.hydrateToken('accessToken');
  }, []);

  useEffect(() => {
    if (!token) return;

    (async () => {
      const user: User = await store.models.users.async.getCurrent();

      console.log('USER', user);
      store.models.users.setTyped('activeUser', user);

      if (requireAdmin && !user.isAdmin) {
        console.log('User must be an admin.');
        store.global.auth.setAccessToken('accessToken', null);
        // TEMP
        store.global.auth.setValue('accessToken', null);
      }

    })();
  }, [token]);

  // TODO remove this
  useEffect(() => {
    if (!accessToken) return;

    store.global.auth.setTokenInLocalStorage(accessToken);

    (async () => {
      const user: User = await store.models.users.async.getCurrent();

      console.log('USER', user);
      store.models.users.setTyped('activeUser', user);
    })();
  }, [accessToken]);
}
