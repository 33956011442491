import React from 'react';
import { observer } from 'mobx-react';

// Components
import SmartTable from '@clientCommon/library/components/smartTable';

// Styles
import './styles.scss';

// Types
import { Row, SortingOrder } from '@clientCommon/library/components/smartTable/types';

type Props = {
  rows: Row[];
  sortingFieldName: string;
  sortingOrder: SortingOrder;
  sortInventory: (fieldName: string, sortingOrder: SortingOrder) => void;
};

const KioskInventoryTable = ({
  rows,
  sortingFieldName,
  sortingOrder,
  sortInventory,
}: Props) => {
  return <SmartTable
    customClassName='c-InventoryTable'
    sortingFieldName={sortingFieldName}
    sortingOrder={sortingOrder}
    onSortIconClick={sortInventory}
    rows={rows}
    columns={[{
      name: 'Drug',
      fieldName: 'name',
      isSortable: true,
      width: '200px',
      render: ({ content }) => {
        const strengthText = content.strength ? ` (${content.strength})` : '';
        return <div>
          <div title={content.name} className='drug-name'>
            { content.name }{ strengthText }
          </div>
          <div>
            <span className='NDC'>
              NDC:
            </span>
            {
              content.NDC
            }
            <span className='quantity'>
              QTY:
            </span>
            {
              content.quantity || 'N/A'
            }
          </div>
        </div>;
      },
    },
    {
      name: 'Assignable',
      fieldName: 'count.assignable',
      isSortable: true,
      render: ({ content }) => (
        <div title={content.count.assignable} className='numbered-item'>
          {
            content.count.assignable
          }
        </div>
      )
    },
    {
      name: 'Unassignable',
      fieldName: 'count.unassignable',
      isSortable: true,
      render: ({ content }) => (
        <div title={content.count.unassignable} className='numbered-item'>
          {
            content.count.unassignable
          }
        </div>
      )
    },
    {
      name: 'Total',
      fieldName: 'count.total',
      isSortable: true,
      render: ({ content }) => (
        <div className='numbered-item'>
          {
            content.count.total
          }
        </div>
      )
    },
    {
      name: 'Is Low Stock',
      fieldName: 'isLowStock',
      isSortable: false,
      render: ({ content }) => (
        <div
          title={content.isLowStock.toString()}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          className={content.isLowStock ? 'threshold-out-of-bounds' : 'threshold-in-bounds'}
        >
          {
            content.isLowStock ? 'Yes' : 'No'
          }
        </div>
      )
    }
  ]}
  />;
};

export default observer(KioskInventoryTable);
