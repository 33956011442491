const doesValueMatchRegex = (regex: RegExp | RegExp[], value: any) => {
  const regexList = Array.isArray(regex) ? regex : [regex];
  const areAllRegexesValid = regexList.every((regex) => regex && regex instanceof RegExp);

  if (!areAllRegexesValid || !value || !value.toString) {
    return true;
  }

  return regexList.some((regex) => regex.test(value.toString()));
};

export default doesValueMatchRegex;
