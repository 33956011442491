import { observer } from 'mobx-react';

import DrugUpsertModal from './components/drugUpsertModal';

import TableLayout from '@clientCommon/library/components/tableLayout';
import Title from '@clientCommon/library/components/tableLayout/components/title';
import TableHeader from '@clientCommon/library/components/tableLayout/components/tableHeader';
import EntriesCount from '@clientCommon/library/components/tableLayout/components/tableHeader/components/entriesCount';
import NewEntryButton from '@clientCommon/library/components/tableLayout/components/tableHeader/components/newEntryButton';
import Paginator from '@clientCommon/library/components/tableLayout/components/paginator';
import Search from '@clientCommon/library/components/searchInput';
import TableContent from '@clientCommon/library/components/tableLayout/components/tableContent';
import SmartTable from '@clientCommon/library/components/smartTable';

import { COLUMNS } from './constants';
import { MAX_TABLE_ENTRIES_PER_PAGE } from './constants';

import { DrugsViewProps } from './types';

const DrugsView = ({
  domain,
  sortDrugs,
  setPaginationPage,
  onDrugsSearch,
  showDrugUpsertModal,
  closeDrugUpsertModal,
}: DrugsViewProps) => (
  <>
    <DrugUpsertModal
      isOpen={domain.showingDrugUpsertModal}
      activeDrug={domain.activeDrug}
      onClose={() => closeDrugUpsertModal()}
    />
    <TableLayout>
      <Title text="Drugs" />
      <TableHeader>
        <div className="left-area">
          <EntriesCount count={domain.entriesCount} caption="Drugs" />
          <Paginator
            currentPage={domain.currentPage}
            entriesCount={domain.entriesCount}
            entriesPerPage={MAX_TABLE_ENTRIES_PER_PAGE}
            changePage={setPaginationPage}
          />
        </div>
        <div className="right-area">
          <NewEntryButton caption="New Drug" onClick={() => showDrugUpsertModal()} />
          <Search
            value={domain.filterValue}
            onChange={(term) => domain.setValue('filterValue', term)}
            onSearch={onDrugsSearch}
          />
        </div>
      </TableHeader>
      <TableContent>
        <SmartTable
          loading={domain.loading}
          sortingFieldName={domain.sortingColumn.fieldName}
          sortingOrder={domain.sortingColumn.sortingOrder}
          onSortIconClick={sortDrugs}
          rows={domain.forTable}
          onRowClick={(row: any) => showDrugUpsertModal(row.id)}
          columns={COLUMNS}
        />
      </TableContent>
    </TableLayout>
  </>
);

export default observer(DrugsView);
