import React from 'react';
import { observer } from 'mobx-react';

import store from '@clientCommon/app/bootstrap/store';

import './headerViewStyles.scss';

const Header = () => (
  <div className="v-Header">
    <div className="left-section">
      <div className="medifriendrx-logo">
        medifriend<span>Rx</span>
      </div>
    </div>
    <div className="mid-section"></div>
    <div className="right-section">
      <button className="log-out-btn" onClick={() => store.models.users.logOut()}>
        Log Out
      </button>
    </div>
  </div>
);

export default observer(Header);
