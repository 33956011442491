import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { isNumber as _isNumber, omit as _omit } from 'lodash';

// Views
import PrescriptionFillsManualEntryView from './prescriptionFillManualEntryView';

// Stores
import store from '@clientCommon/app/bootstrap/store';
import getDomain from './domain';

// Utils
import parseDbDateOfBirth from '@clientCommon/library/utils/parseDbDateOfBirth';

import { PrescriptionFillUpsertWithReferencesBody } from '@clientCommon/app/models/prescriptionFills/asyncActions/upsertWithReferences';

const PrescriptionFillsManualEntryContainer = () => {
  const domain = useMemo(() => getDomain({}).create(), []);

  const listKioskOptions = useCallback(async () => {
    const kiosks = await store.models.kiosks.async.list();
    domain.setValue('kiosks', kiosks as any);
  }, [domain]);

  const listDrugSuggestions = useCallback(
    async <Field extends keyof typeof domain.suggestions.drug>(field: Field) => {
      domain.setSuggestionFieldValue('drug', field, []);

      const currentValue = domain.drug[field];
      const response = await store.models.drugs.async.list({ [field]: currentValue });

      domain.setSuggestionFieldValue('drug', field, response || []);
    },
    [domain]
  );

  const onDrugInputChange = useCallback(
    async <Field extends keyof typeof domain.drug, Value extends typeof domain.drug[Field]>(
      field: Field,
      value: Value
    ) => {
      domain.setDrugFieldValue(field, value);

      // @ts-ignore
      if (domain.suggestions.drug[field]) {
        const currentValue = domain.drug[field];
        if (currentValue.length > 2) {
          // @ts-ignore
          return listDrugSuggestions(field);
        } else {
          // @ts-ignore
          return domain.setSuggestionFieldValue('drug', field, []);
        }
      }
    },
    [domain, listDrugSuggestions]
  );

  const onDrugSuggestionClick = useCallback(
    (drug: any, filteredField: keyof typeof domain.suggestions.drug) => {
      const { name, NDC, quantity } = drug;

      if (drug[filteredField]) {
        domain.setDrugFieldValue(filteredField, drug[filteredField]);
      }

      if (!domain.drug.name) {
        domain.setDrugFieldValue('name', name);
      }

      if (!domain.drug.NDC) {
        domain.setDrugFieldValue('NDC', NDC);
      }

      if (!domain.drug.quantity) {
        domain.setDrugFieldValue('quantity', quantity || '');
      }

      domain.resetSuggestions('drug');
    },
    [domain]
  );

  const listPatientSuggetions = useCallback(
    async <Field extends keyof typeof domain.suggestions.patient>(field: Field) => {
      domain.setSuggestionFieldValue('patient', field, []);

      const currentValue = domain.patient[field];
      const response = await store.models.patients.async.list({ [field]: currentValue });

      domain.setSuggestionFieldValue('patient', field, response || []);
    },
    [domain]
  );

  const onPatientInputChange = useCallback(
    async <Field extends keyof typeof domain.patient, Value extends typeof domain.patient[Field]>(
      field: Field,
      value: Value
    ) => {
      domain.setPatientFieldValue(field, value);

      // @ts-ignore
      if (domain.suggestions.patient[field]) {
        const currentValue = domain.patient[field];
        if (currentValue.length > 2) {
          // @ts-ignore
          return listPatientSuggetions(field);
        } else {
          // @ts-ignore
          return domain.setSuggestionFieldValue('patient', field, []);
        }
      }
    },
    [domain, listPatientSuggetions]
  );

  const onPatientSuggestionClick = useCallback(
    (patient: any, filteredField: keyof typeof domain.suggestions.patient) => {
      const { firstName, lastName, externalPharmacyId, DOB, email, phoneNumber } = patient;

      if (patient[filteredField]) {
        domain.setPatientFieldValue(filteredField, patient[filteredField]);
      }

      if (!domain.patient.firstName) {
        domain.setPatientFieldValue('firstName', firstName);
      }

      if (!domain.patient.lastName) {
        domain.setPatientFieldValue('lastName', lastName);
      }

      if (!domain.patient.externalPharmacyId && externalPharmacyId) {
        domain.setPatientFieldValue('externalPharmacyId', externalPharmacyId);
      }

      if (!domain.patient.DOB && DOB) {
        domain.setPatientFieldValue('DOB', parseDbDateOfBirth(DOB) || '');
      }

      if (!domain.patient.email && email) {
        domain.setPatientFieldValue('email', email);
      }

      if (!domain.patient.phoneNumber && phoneNumber) {
        domain.setPatientFieldValue('phoneNumber', phoneNumber);
      }

      domain.resetSuggestions('patient');
    },
    [domain]
  );

  const listPhysicianSuggestions = useCallback(
    async <Field extends keyof typeof domain.suggestions.physician>(field: Field) => {
      domain.setSuggestionFieldValue('physician', field, []);

      const currentValue = domain.physician[field];
      const response = await store.models.physicians.async.list({ [field]: currentValue });

      domain.setSuggestionFieldValue('physician', field, response || []);
    },
    [domain]
  );

  const onPhysicianInputChange = useCallback(
    async <Field extends keyof typeof domain.physician, Value extends typeof domain.physician[Field]>(
      field: Field,
      value: Value
    ) => {
      domain.setPhysicianFieldValue(field, value);

      if (domain.suggestions.physician[field]) {
        const currentValue = domain.physician[field];
        if (currentValue.length > 2) {
          return listPhysicianSuggestions(field);
        } else {
          return domain.setSuggestionFieldValue('physician', field, []);
        }
      }
    },
    [domain, listPhysicianSuggestions]
  );

  const onPhysicianSuggestionClick = useCallback(
    (physician: any, filteredField: keyof typeof domain.suggestions.physician) => {
      const { firstName, lastName, NPI, SPI, stateLicenseNumber, externalPharmacyId } = physician;

      if (physician[filteredField]) {
        domain.setPhysicianFieldValue(filteredField, physician[filteredField]);
      }

      if (!domain.physician.firstName) {
        domain.setPhysicianFieldValue('firstName', firstName);
      }

      if (!domain.physician.lastName) {
        domain.setPhysicianFieldValue('lastName', lastName);
      }

      if (!domain.physician.externalPharmacyId && externalPharmacyId) {
        domain.setPhysicianFieldValue('externalPharmacyId', externalPharmacyId);
      }

      if (!domain.physician.NPI && NPI) {
        domain.setPhysicianFieldValue('NPI', NPI);
      }

      if (!domain.physician.SPI && SPI) {
        domain.setPhysicianFieldValue('SPI', SPI);
      }

      if (!domain.physician.stateLicenseNumber && stateLicenseNumber) {
        domain.setPhysicianFieldValue('stateLicenseNumber', stateLicenseNumber);
      }

      domain.resetSuggestions('physician');
    },
    [domain]
  );

  const upsertPrescriptionFillWithReferences = useCallback(async () => {
    const patientDOB = domain.patient.DOB;
    if (!patientDOB) {
      return;
    }
    const [month, date, year] = patientDOB.split('/');
    const patient = {
      ...domain.patient,
      DOB: `${year}-${month}-${date}`,
    };

    const prescription = _isNumber(domain.prescription.refills)
      ? domain.prescription
      : _omit(domain.prescription, ['refills']);

    try {
      const body: PrescriptionFillUpsertWithReferencesBody = {
        prescriptionFill: {
          ...domain.prescriptionFill,
          fillNumber: domain.prescriptionFill.fillNumber || 1,
        },
        // @ts-ignore
        prescription,
        drug: domain.drug,
        patient,
        physician: domain.physician,
      };
      if (domain.kioskOption && domain.kioskOption.value) {
        body.kioskId = domain.kioskOption.value;
      }
      await store.models.prescriptionFills.async.upsertWithReferences(body);
      domain.setValue('wasManualOrderFulfilled', true);
    } catch (e) {
      domain.setValue('wasManualOrderFulfilled', false);
    }
  }, [domain]);

  useEffect(() => {
    listKioskOptions();

    return () => {
      domain.reset();
    };
  }, [domain, listKioskOptions]);

  useEffect(() => {
    if (domain.drug.NDC) {
      store.models.prescriptions.async
        .getDirections(domain.drug.NDC, domain.drug.quantity)
        .then((directions) => domain.setPrescriptionFieldValue('directions', directions));
    }
  }, [domain.drug.NDC, domain.drug.quantity]);

  return (
    <PrescriptionFillsManualEntryView
      domain={domain}
      onDrugInputChange={onDrugInputChange}
      onDrugSuggestionClick={onDrugSuggestionClick}
      onPatientInputChange={onPatientInputChange}
      onPatientSuggestionClick={onPatientSuggestionClick}
      onPhysicianInputChange={onPhysicianInputChange}
      onPhysicianSuggestionClick={onPhysicianSuggestionClick}
      upsertPrescriptionFillWithReferences={upsertPrescriptionFillWithReferences}
    />
  );
};

export default observer(PrescriptionFillsManualEntryContainer);
