import React from 'react';

import './styles.scss';

export default function H4({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
  return (
    <h4 className={`h4 ${className}`} {...props}>
      {children}
    </h4>
  );
}
