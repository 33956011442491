import constructRequestQuery from './constructRequestQuery';
import countDrugs from './countDrugs';
import listDrugs from './listDrugs';

import { DrugsDomain } from '../types';

const listAndCountDrugs = async (domain: DrugsDomain) => {
  const query = constructRequestQuery(domain);

  return Promise.all([listDrugs(domain, query), countDrugs(domain, query)]);
};

export default listAndCountDrugs;
