const setInterruptedSequenceRanges = (
  ranges: { from: number; to: number },
  currentPage: number,
  maxPagesVisible: number
) => {
  if (currentPage - 1 > 0) {
    ranges.from = currentPage - 1;
    ranges.to = currentPage + maxPagesVisible - 2;
  } else {
    ranges.from = currentPage;
    ranges.to = currentPage + maxPagesVisible - 1;
  }
};

const setContinuousSequenceRanges = (
  ranges: { from: number; to: number },
  pagesCount: number,
  maxPagesVisible: number
) => {
  if (pagesCount - maxPagesVisible + 1 > 0) {
    ranges.from = pagesCount - maxPagesVisible + 1;
  } else {
    ranges.from = 1;
  }

  ranges.to = pagesCount + 1;
};

export const detectRanges = ({
  showingDots,
  currentPage,
  pagesCount,
  maxPagesVisible,
}: {
  showingDots: boolean;
  currentPage: number;
  pagesCount: number;
  maxPagesVisible: number;
}) => {
  const ranges = {
    from: 0,
    to: 0,
  };

  if (showingDots) {
    setInterruptedSequenceRanges(ranges, currentPage, maxPagesVisible);
  } else {
    setContinuousSequenceRanges(ranges, pagesCount, maxPagesVisible);
  }

  return {
    rangeFrom: ranges.from,
    rangeTo: ranges.to,
  };
};
