import React from 'react';
import { observer } from 'mobx-react';
import Select, { Props as ReactSelectProps } from 'react-select';

import ContentScreen from '@clientCommon/library/components/contentScreen';

import './titledInputStyles.scss';

type Props = ReactSelectProps & {
  caption: string;
  wrapperCustomStyles?: Object;
  inputFieldClassNames?: string[];
  loadMore?: () => void;
  isLoading?: boolean;
};

const TitledSelect = ({
  caption,
  wrapperCustomStyles,
  inputFieldClassNames,
  loadMore,
  isLoading = false,
  ...reactSelectProps
}: Props) => {
  return (
    <div className="c-TitledSelect" style={wrapperCustomStyles}>
      <ContentScreen active={isLoading} />
      <Select
        isClearable={true}
        {...reactSelectProps}
        className={inputFieldClassNames?.join(' ')}
        onMenuScrollToBottom={loadMore}
      />
      {caption && <div className="caption">{caption}</div>}
    </div>
  );
};

export default observer(TitledSelect);
