import * as REGEX from './regex';

export const MANUAL_ENTRY_PRESCRIPTION_DIRECTIONS_BY_DRUG_CACHE_KEY = 'manualEntryPrescriptionDirectionsByDrug';

export const PRESCRIPTION_FILL_PARAMS = {
  'prescriptionFill.copay': {
    required: true,
    regex: REGEX.POSITIVE_FLOAT,
  },
  'prescriptionFill.fillNumber': {
    required: false,
    regex: REGEX.POSITIVE_INTEGER,
  },
  'prescription.rxId': {
    required: true,
    regex: REGEX.POSITIVE_INTEGER,
  },
  'prescription.refills': {
    required: false,
    regex: REGEX.POSITIVE_INTEGER,
  },
  'prescription.directions': {
    required: false,
    regex: undefined,
  },
  'drug.name': {
    required: true,
    regex: undefined,
  },
  'drug.NDC': {
    required: true,
    regex: [REGEX.NDC, REGEX.NDC_11_DIGIT_BILLING],
  },
  'drug.quantity': {
    required: true,
    regex: REGEX.POSITIVE_FLOAT,
  },
  'patient.firstName': {
    required: true,
    regex: undefined,
  },
  'patient.lastName': {
    required: true,
    regex: undefined,
  },
  'patient.DOB': {
    required: true,
    regex: REGEX.MM_DD_YYYY,
  },
  'patient.email': {
    required: false,
    regex: REGEX.EMAIL,
  },
  'patient.phoneNumber': {
    required: false,
    regex: REGEX.PHONE_NUMBER,
  },
  'patient.externalPharmacyId': {
    required: false,
    regex: REGEX.POSITIVE_INTEGER,
  },
  'physician.firstName': {
    required: true,
    regex: undefined,
  },
  'physician.lastName': {
    required: true,
    regex: undefined,
  },
  'physician.NPI': {
    required: false,
    regex: REGEX.POSITIVE_INTEGER,
  },
  'physician.SPI': {
    required: false,
    regex: REGEX.POSITIVE_INTEGER,
  },
  'physician.stateLicenseNumber': {
    required: false,
    regex: undefined,
  },
  'physician.externalPharmacyId': {
    required: false,
    regex: REGEX.POSITIVE_INTEGER,
  },
};
