import React from 'react';
import { observer } from 'mobx-react';

import './modalButtonStyles.scss';

type Props = {
  text: string;
  onClick: () => void;
  color: 'blue' | 'red';
  isDisabled: boolean;
};

const ModalButton = ({ text, onClick, color = 'blue', isDisabled }: Props) => (
  <button disabled={isDisabled} className={`c-ModalButton c-ModalButton--${color}`} onClick={onClick}>
    {text}
  </button>
);

export default observer(ModalButton);
