import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

// Views
import KioskStorageView from './kioskStorageView';

// Types
import { KioskStorageContainerProps } from './types';

// Route store
import getDomain from './domain';

// Global Stores
import stores from '@clientCommon/app/bootstrap/store';

const KioskStorageContainer = ({ kioskId }: KioskStorageContainerProps) => {
  const domain = useMemo(() => getDomain().create(), []);

  useEffect(() => {
    (async () => {
      if (kioskId) {
        const inventory = await stores.models.kiosks.async.getComputedInventory(kioskId);
        if (inventory) {
          domain.setValue('inventory', inventory);
        }
      }
    })();
  }, [kioskId]);

  useEffect(() => {
    return () => {
      domain.reset();
    };
  }, []);

  return <KioskStorageView
    domain={domain}
  />;
};

export default observer(KioskStorageContainer);
