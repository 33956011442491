import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';

// Types
import { LoginContainerProps } from './types';

// Views
import LoginView from './loginView';

// Domain
import getDomain from './domain';

import store from '@clientCommon/app/bootstrap/store';

function LoginContainer({ onLogin }: LoginContainerProps) {
  const domain = useMemo(
    () =>
      getDomain({
        onLogin: (accessToken) => {
          store.global.auth.setValue('accessToken', accessToken); // TODO remove this
          store.global.auth.setAccessToken('accessToken', accessToken);
          onLogin && onLogin(accessToken);
        },
      }).create(),
    []
  );

  return <LoginView domain={domain} />;
}

export default observer(LoginContainer);
