import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

type Props = {
  customClassName?: string;
  children: React.ReactNode;
};

const TableLayoutView = ({ customClassName, children }: Props) => (
  <div className={`c-TableLayoutView ${customClassName || ''}`}>{children}</div>
);

export default observer(TableLayoutView);
