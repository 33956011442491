import React from 'react';

import './styles.scss';

export default function H3({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) {
  return (
    <h3 className={`h3 ${className}`} {...props}>
      {children}
    </h3>
  );
}
