import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import DrugsView from './drugsView';

import { listAndCountDrugs } from './helpers';

import getDomain from './domain';

import { SortingOrder } from '@clientCommon/library/components/smartTable/types';

const DrugsContainer = () => {
  const domain = useMemo(() => getDomain({}).create(), []);

  useEffect(() => {
    listAndCountDrugs(domain);

    return () => {
      domain.reset();
    };
  }, [domain]);

  const setPaginationPage = useCallback(
    async (value: number) => {
      domain.setValue('currentPage', value);
      return listAndCountDrugs(domain);
    },
    [domain]
  );

  const closeDrugUpsertModal = useCallback(async () => {
    domain.setValue('activeDrugId', null);

    await listAndCountDrugs(domain);

    domain.setValue('showingDrugUpsertModal', false);
  }, [domain]);

  const showDrugUpsertModal = useCallback(
    (drugId?: string) => {
      if (drugId) {
        domain.setValue('activeDrugId', drugId);
      }

      domain.setValue('showingDrugUpsertModal', true);
    },
    [domain]
  );

  const onDrugsSearch = useCallback(async () => {
    domain.setValue('currentPage', 1);
    return listAndCountDrugs(domain);
  }, [domain]);

  const sortDrugs = useCallback(
    async (fieldName: string, sortingOrder: SortingOrder) => {
      if (!['name', 'NDC', 'UPC'].includes(fieldName)) {
        return;
      }

      domain.setValue('sortingColumn', {
        ...domain.sortingColumn,
        fieldName: fieldName as 'name' | 'NDC' | 'UPC',
        sortingOrder,
      });

      return listAndCountDrugs(domain);
    },
    [domain]
  );

  return (
    <DrugsView
      domain={domain}
      sortDrugs={sortDrugs}
      setPaginationPage={setPaginationPage}
      onDrugsSearch={onDrugsSearch}
      showDrugUpsertModal={showDrugUpsertModal}
      closeDrugUpsertModal={closeDrugUpsertModal}
    />
  );
};

export default observer(DrugsContainer);
