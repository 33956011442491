import constructModel from './constructModel';

import * as containers from '@clientCommon/app/models/containers';
import * as drugs from '@clientCommon/app/models/drugs';
import * as healthcareSites from '@clientCommon/app/models/healthcareSites';
import * as identifyingAccessTokens from '@clientCommon/app/models/identifyingAccessTokens';
import * as kiosks from '@clientCommon/app/models/kiosks';
import * as legaleses from '@clientCommon/app/models/legaleses';
import * as locations from '@clientCommon/app/models/locations';
import * as lotDrugRecalls from '@clientCommon/app/models/lotDrugRecalls';
import * as lots from '@clientCommon/app/models/lots';
import * as patientImages from '@clientCommon/app/models/patientImages';
import * as patients from '@clientCommon/app/models/patients';
import * as payments from '@clientCommon/app/models/payments';
import * as pharmacies from '@clientCommon/app/models/pharmacies';
import * as physicians from '@clientCommon/app/models/physicians';
import * as prescriptionFills from '@clientCommon/app/models/prescriptionFills';
import * as prescriptions from '@clientCommon/app/models/prescriptions';
import * as users from '@clientCommon/app/models/users';

const models = {
  containers: constructModel(containers),
  drugs: constructModel(drugs),
  healthcareSites: constructModel(healthcareSites),
  identifyingAccessTokens: constructModel(identifyingAccessTokens),
  kiosks: constructModel(kiosks),
  legaleses: constructModel(legaleses),
  locations: constructModel(locations),
  lotDrugRecalls: constructModel(lotDrugRecalls),
  lots: constructModel(lots),
  patientImages: constructModel(patientImages),
  patients: constructModel(patients),
  payments: constructModel(payments),
  pharmacies: constructModel(pharmacies),
  physicians: constructModel(physicians),
  prescriptionFills: constructModel(prescriptionFills),
  prescriptions: constructModel(prescriptions),
  users: constructModel(users),
};

export default models;

export type Models = typeof models;
