import React from 'react';
import { Link } from 'react-router-dom';

import { LINKS } from './constants';

import './navbarViewStyles.scss';

type Props = {
  isActiveRoute: (route: string) => boolean;
};

const NavbarView = ({ isActiveRoute }: Props) => {
  return (
    <div className="v-Navbar">
      {LINKS.map(({ route, title, icon }) => {
        const active = isActiveRoute(route);
        return (
          <Link key={route} to={route}>
            <div className="route-container">
              <img alt={title} className="icon" src={active ? icon.active : icon.disabled} />
              <div className={`route ${active && 'route-active'}`}>{title}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default NavbarView;
