import React from 'react';
import { observer } from 'mobx-react';

import constructStockingPeriod from './helpers/constructStockingPeriod';

import ContentGate from '@clientCommon/library/components/contentGate/index.web.p';

import CalendarIcon from '@clientCommon/library/assets/icons/calendar-blue.svg';
import ContainerIcon from '@clientCommon/library/assets/icons/container-active.svg';
import ContainerRedIcon from '@clientCommon/library/assets/icons/container-red.svg';
import HashtagIcon from '@clientCommon/library/assets/icons/hashtag-active.svg';

import { LotInfoViewProps } from './types';

import './lotInfoStyles.scss';

const InfoView = ({
  activeLot,
  isBusy,
  drugsByLotId,
  drugsList,
  unrecalledDrugsCount,
  recallDrugIds,
  addRecallDrugId,
  clearRecallDrugIds,
  recallSelectedDrugs,
}: LotInfoViewProps) => {
  console.log('recallDrugIds: ', recallDrugIds);

  return (
    <div className="c-LotInfo">
      <div className="data">
        <div className="data-row">
          <div className="name">
            <img alt="Lot Number" className="icon" src={HashtagIcon} />
            <div className="text">Number</div>
          </div>
          <div className="value">{activeLot.number}</div>
        </div>
        <div className="data-row">
          <div className="name">
            <img alt="Container" className="icon" src={ContainerIcon} />
            <div className="text">Total Containers #</div>
          </div>
          <div className="value">{activeLot.totalContainersCount}</div>
        </div>
        <div className="data-row">
          <div className="name">
            <img
              alt="Container"
              className="icon"
              src={activeLot.dispensedContainersCount ? ContainerRedIcon : ContainerIcon}
            />
            <div className={`text ${activeLot.dispensedContainersCount && 'text-red'}`}>Dispensed Containers #</div>
          </div>
          <div className={`value ${activeLot.dispensedContainersCount && 'value-red'}`}>
            {activeLot.dispensedContainersCount}
          </div>
        </div>
        <div className="data-row">
          <div className="name">
            <img alt="Calendar" className="icon" src={CalendarIcon} />
            <div className="text">Stocking Period</div>
          </div>
          <div className="value">{constructStockingPeriod(activeLot)}</div>
        </div>
      </div>
      <ContentGate className="drug-recalls-container" active={isBusy} fadeDuration={500}>
        {!!recallDrugIds.length && (
          <div className="recall-prompt">
            <div className="header">
              Confirm recall of the following drugs in Lot <span className="lot-number">{activeLot.number}</span>
            </div>
            <ul className="recall-drug-list">
              {recallDrugIds.map((drugId) => (
                <li className="recall-drug" key={drugId}>
                  {drugsByLotId[drugId].name}
                </li>
              ))}
            </ul>
            <div className="recall-options">
              <button className="btn btn-blue" onClick={() => recallSelectedDrugs()}>
                Yes, Recall
              </button>
              <button className="btn btn-red" onClick={() => clearRecallDrugIds()}>
                Cancel
              </button>
            </div>
          </div>
        )}
        <div className="drug-list-container">
          <div className="header">
            <div className="title">Drug Types Found In Lot</div>
            <div className="header-actions">
              {unrecalledDrugsCount > 1 && (
                <button
                  className="header-action btn btn-red"
                  onClick={() => {
                    const recallDrugIds: string[] = [];
                    drugsList.forEach((drug) => {
                      if (!drug.lotDrugRecall) {
                        recallDrugIds.push(drug.id);
                      }
                    });
                    addRecallDrugId(recallDrugIds);
                  }}
                >
                  Recall All
                </button>
              )}
            </div>
          </div>
          <div className="drugs-list">
            {drugsList.map((drug) => (
              <div key={drug.id} className="drug-option-container">
                <div className="drug-info">
                  <div className="drug-name">{drug.name}</div>
                  <div className="drug-NDC">{drug.NDC}</div>
                </div>
                <div className="drug-actions">
                  <button
                    className="drug-action btn btn-red"
                    disabled={!!drug.lotDrugRecall}
                    onClick={() => addRecallDrugId(drug.id)}
                  >
                    Recall
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ContentGate>
    </div>
  );
};

export default observer(InfoView);
