import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ArrowBack from './components/arrowBack';
import Pages from './components/pages';
import ArrowForward from './components/arrowForward';

import './index.scss';

type Props = {
  entriesCount: number;
  currentPage: number;
  changePage: (newPage: number) => void;
  entriesPerPage?: number;
  maxPagesVisible?: number;
};

const Paginator = ({ entriesCount, currentPage, changePage, entriesPerPage = 10, maxPagesVisible = 4 }: Props) => {
  const [isShowingDotsSeparator, setIsShowingDotsSeparator] = useState(false);
  const pagesCount = Math.ceil(entriesCount / entriesPerPage);

  useEffect(() => {
    const pagesCount = Math.ceil(entriesCount / entriesPerPage);

    if (pagesCount - currentPage > maxPagesVisible - 1) {
      setIsShowingDotsSeparator(true);
    } else {
      setIsShowingDotsSeparator(false);
    }
  }, [currentPage, entriesCount, entriesPerPage, maxPagesVisible]);

  return (
    <div className="c-Paginator">
      <div className="title">Page</div>
      <ArrowBack currentPage={currentPage} changePage={changePage} />
      <Pages
        showingDots={isShowingDotsSeparator}
        currentPage={currentPage}
        pagesCount={pagesCount}
        changePage={changePage}
        maxPagesVisible={maxPagesVisible}
      />
      <ArrowForward currentPage={currentPage} pagesCount={pagesCount} changePage={changePage} />
    </div>
  );
};

export default observer(Paginator);
