import { MAX_TABLE_ENTRIES_PER_PAGE } from '../constants';

import { DrugsDomain, DrugsQuery } from '../types';

const constructRequestQuery = (domain: DrugsDomain) => {
  const query: DrugsQuery = {
    offset: MAX_TABLE_ENTRIES_PER_PAGE * (domain.currentPage - 1),
    limit: MAX_TABLE_ENTRIES_PER_PAGE,
  };

  if (domain.filterValue) {
    query.filter = {
      name: domain.filterValue,
      NDC: domain.filterValue,
      UPC: domain.filterValue,
    };
  }

  if (domain.sortingColumn.fieldName && domain.sortingColumn.sortingOrder) {
    query.order = {
      field: domain.sortingColumn.fieldName,
      direction: domain.sortingColumn.sortingOrder,
    };
  }

  return query;
};

export default constructRequestQuery;
