import DrugIcon from '@clientCommon/library/assets/icons/drug.svg';
import DrugActiveIcon from '@clientCommon/library/assets/icons/drug-active.svg';
import KioskIcon from '@clientCommon/library/assets/icons/kiosk.svg';
import KioskActiveIcon from '@clientCommon/library/assets/icons/kiosk-active.svg';
import LotIcon from '@clientCommon/library/assets/icons/ln.svg';
import LotActiveIcon from '@clientCommon/library/assets/icons/ln-active.svg';
import PrescriptionsIcon from '@clientCommon/library/assets/icons/prescriptions.svg';
import PrescriptionsActiveIcon from '@clientCommon/library/assets/icons/prescriptions-active.svg';

export const LINKS = [
  {
    title: 'Kiosks',
    route: '/kiosks',
    icon: {
      active: KioskActiveIcon,
      disabled: KioskIcon,
    },
  },
  // {
  //   title: 'Drugs',
  //   route: '/drugs',
  //   icon: {
  //     active: DrugActiveIcon,
  //     disabled: DrugIcon,
  //   },
  // },
  {
    title: 'Lots',
    route: '/lots',
    icon: {
      active: LotActiveIcon,
      disabled: LotIcon,
    },
  },
  {
    title: 'Manual Entry',
    route: '/prescription-fill-manual-entry',
    icon: {
      active: PrescriptionsActiveIcon,
      disabled: PrescriptionsIcon,
    },
  },
];
