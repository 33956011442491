import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

type Props = {
  children: React.ReactNode;
};

const TableContent = ({ children }: Props) => <div className="c-TableLayoutTableContent">{children}</div>;

export default observer(TableContent);
