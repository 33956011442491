import React from 'react';
import { observer } from 'mobx-react';

import './index.scss';

type Props = {
  caption: string;
  onClick: () => void;
};

const NewEntryButton = ({ caption, onClick }: Props) => (
  <div className="c-TableLayoutTableHeaderNewEntryButton" onClick={onClick}>
    <div className="plus-sign">+</div>
    <input type="button" className="new-entry-btn" value={caption} />
  </div>
);

export default observer(NewEntryButton);
